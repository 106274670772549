<app-header [headerTitle]="headerTitle"></app-header>

<div  class="responsive-container">
  <div class="wrapper">
    <div  class="wrapper-div">
      <div class="responsive-image-wrapper" *ngFor="let image of images">
        <img [src]="image.src" [alt]="image.name" 
             (click)="!image.disabled && onImageClick(image.route)" 
             [ngClass]="{'disabled-image': image.disabled}" 
             class="responsive-image" 
             style="object-fit: cover; margin: 0 auto;">
        <h3 class="text-font">{{ image.name }}</h3>
      </div>
    </div>
  </div>
</div>