import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './components/auth/auth.module';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';
import { SessionService } from './services/session.service';
import { QRCodeModule } from 'angularx-qrcode';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import Amplify from 'aws-amplify';
import { UserModule } from './components/user-module/user.module';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { MaterialModule } from './material.module';
import { AgGridModule } from 'ag-grid-angular';
import { AuthGuard } from './services/auth-guard';
import { HelpService } from './services/help.service';

Amplify.configure({
	Auth: environment.AMPLIFY,
});

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    QRCodeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    AuthModule,
    UserModule,
    MaterialModule,
    AgGridModule
  ],
  exports: [
    HomeComponent,
    MaterialModule
  ],
  providers: [ApiService, AuthService,AuthGuard, SessionService,HelpService,],
  bootstrap: [AppComponent]
})
export class AppModule { }
