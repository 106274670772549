import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor() { 
    // Retrieve the stored flag from sessionStorage on service initialization
    const storedFlag = sessionStorage.getItem('timsheet_approvar_flag');
    if (storedFlag) {
      this.managerPermissionDataSubject.next(storedFlag);
    }

    this.trackClicks();
  }
  jiraData: any = []
  selectedDate: any
  status: any
  timeSheetMasterData: any;
  isApprovalView: boolean = false;
  private dataSource = new BehaviorSubject<boolean>(false);  
  currentData = this.dataSource.asObservable();
  private managerPermissionDataSubject = new BehaviorSubject<string | null>(null);
  managerPermissionData$ = this.managerPermissionDataSubject.asObservable();

  private workModeDataSubject = new BehaviorSubject<any>(null);
  private managerCommentsByDate: { [key: string]: string } = {};
  private mouseClickSubject = new Subject<MouseEvent>();
  // Expose the mouse clicks as an observable
  mouseClick$ = this.mouseClickSubject.asObservable();

   // Track mouse clicks on the entire document
   private trackClicks(): void {
    document.addEventListener('click', (event: MouseEvent) => {
      this.mouseClickSubject.next(event);
    });
  }

  getComments(): { [key: string]: string } {
    return this.managerCommentsByDate;
  }

  setComment(date: string, comment: string,view?:string): void {
    if(view) this.managerCommentsByDate = {};
    this.managerCommentsByDate[date] = comment;
  }

  setWorkMode(data:any): void{
    this.workModeDataSubject.next(data)
  }

  getWorkMode() {
    return this.workModeDataSubject.getValue();
  }

  checkManagerPermission(data: any): void {
    const timsheetApprovarFlag = data[0]?.timsheet_approvar_flag;
    if (timsheetApprovarFlag) {
      this.managerPermissionDataSubject.next(timsheetApprovarFlag);
      sessionStorage.setItem('timsheet_approvar_flag', timsheetApprovarFlag);
    }
  }
  changeData(value: boolean) {
    this.dataSource.next(value);  // Update the BehaviorSubject with the new boolean value
  }


  validateDatas(data: any[]): boolean {
    // Define the required keys
    const requiredKeys = ['project_key', 'client_key', 'brand_key', 'task_key', 'jira_no'];

    // Iterate over each item in the data array
    for (const item of data) {
      // Check for required keys
      for (const key of requiredKeys) {
        if (item[key] == '' || item[key] == null || item[key] == undefined) {
          console.error(`Missing required key: ${key}`);
          return false;
        }
      }

      // Validate date fields and their formats
      for (const key in item) {
        if (!requiredKeys.includes(key) && !this.isValidDate(key)) {
          console.error(`Invalid date key: ${key}`);
          return false;
        }
        if (!requiredKeys.includes(key) && !this.isValidValue(item[key])) {
          console.error(`Invalid value format for key ${key}: ${item[key]}`);
          return false;
        }
      }
    }
    return true;
  }

  // Helper method to validate date keys
  private isValidDate(dateString: string): boolean {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(dateString);
  }

  // Helper method to validate the value format "number|string"
  private isValidValue(value: string): boolean {
    const valueParts = value.split('|');
    if (valueParts.length !== 2) {
      return false;
    }

    const [numberPart, stringPart] = valueParts;

    // Validate the number part
    if (isNaN(Number(numberPart))) {
      return false;
    }
    if (numberPart == ''||numberPart == ' ') {
      return false;
    }

    // Validate the string part
    let hour: any = Number(numberPart) > 0
    if (hour && (typeof stringPart !== 'string' || stringPart.trim() === '')) {
      return false;
    }

    return true;
  }






  hoursvalidateData(data: any[]): any {
    // Define the required keys
    const requiredKeys = ['client_key', 'project_key', 'brand_key', 'task_key', 'jira_no'];
    let error: any = ''
    // Iterate over each item in the data array
    for (const item of data) {
      // Check for required keys
      for (const key of requiredKeys) {
        if (item[key] == '' || item[key] == null || item[key] == undefined) {
          error += key.replace('_key', ' name') + ','
          // console.error(`Missing required key: ${key}`);
          // return false;
        }
      }
    }
    if (error.length > 0) { return { condition: false, error: error } } else {
      return { condition: true, error: error }
    }

  }

  savehoursvalidateData(data: any[]): any {
    // Define the required keys
    const requiredKeys = ['client_key', 'project_key', 'brand_key', 'task_key', 'jira_no'];
    let error: any = ''
    let hours:any
    // Iterate over each item in the data array
    for (const item of data) {
      // Check for required keys
      for (const key of requiredKeys) {
        if (item[key] == '' || item[key] == null || item[key] == undefined) {
          error += key.replace('_key', ' name') + ','
          // console.error(`Missing required key: ${key}`);
          // return false;
        }
      }
      // Validate date fields and their formats
      for (const key in item) {
        if (!requiredKeys.includes(key) && !this.isValidDates(key)) {
          console.error(`Invalid date key: ${key}`);
          // return false;
        }
        if (!requiredKeys.includes(key) && !this.isValidValues(item[key])) {
          let data: any = item[key].split('|')
        
          hours += parseFloat(data[0] || 0)
          if (hours>0){
          console.error(hours+`hours  value format for key ${key}: ${item[key]}`);

          }else{
          error += (data[0] == '') ? ` missing value in ${moment(key).format('DD MMM')} ,` : `missing comments in ${moment(key).format('DD MMM')} Value= ${data[0]} ,`//: ${item[key]}
          console.error(`Invalid value format for key ${key}: ${item[key]}`);}
          // return false;
        }
      }
    }
    if (hours == 0) { error += `rows cannot be empty` }
    if (error.length > 0) { return { condition: false, error: error } } else {
      return { condition: true, error: error }
    }

  }



  arraysEqual(arr1:any, arr2:any) {
    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) return false;
  
    // Compare each object in the arrays
    for (let i = 0; i < arr1.length; i++) {
      if (!this.objectsEqual(arr1[i], arr2[i])) return false;
    }
  
    // If all objects are equal
    return true;
  }
  
   objectsEqual(obj1:any, obj2:any) {
    // Check if both values are objects
    if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
      // Get the keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      // Check if the objects have the same number of keys
      if (keys1.length !== keys2.length) return false;
  
      // Compare each key-value pair in the objects
      for (let key of keys1) {
        if (!this.objectsEqual(obj1[key], obj2[key])) return false;
      }
  
      // If all key-value pairs are equal
      return true;
    } else {
      // If the values are not objects, perform a simple comparison
      return obj1 === obj2;
    }
  }
  

  
  validateData(data: any[]): { condition: boolean, error: string } {
    //console.log(data);

    const requiredKeys = ['client_key', 'brand_key', 'project_key', 'task_key', 'jira_no'];
    const dateKeys = Object.keys(data[0]).filter(key => this.isValidDates(key));
   // console.log("Date Keys:", dateKeys); // Debugging line
    const errors: string[] = [];

    for (const item of data) {
        // Check required keys
        for (const key of requiredKeys) {
            if (item[key] === '' || item[key] == null || item[key] === undefined) {
                let err: any = errors.find(res => res === 'Please fill in all required fields' || res === 'Missing comment');
                if (!err) {
                    errors.push('Please fill in all required fields');
                }
            }
        }

        // Validate date fields and ensure at least one is valid
        let isAnyDateFieldValid = false;
        for (const key of dateKeys) {
            const dateValue = item[key];
            if (!this.isValidDates(key)) {
                errors.push(`Invalid date key: ${key}`);
            } else {
                if (this.isValidValues(dateValue)) {
                    isAnyDateFieldValid = true;
                } else {
                    const dataParts = dateValue.split('|');
                    const hours = parseFloat(dataParts[0] || 0);

                    // Check if hours are greater than 0
                    if (hours > 0) {
                        // Check if the primary comment is missing
                        if (!dataParts[1] || dataParts[1].trim() === '') {
                            let err: any = errors.find(res => res === 'Missing comment');
                            if (!err) {
                                errors.push('Missing comment');
                            }
                        } else {
                            isAnyDateFieldValid = true; // A valid comment exists
                        }
                    }
                }
            }
        }

        if (!isAnyDateFieldValid) {
            let err: any = errors.find(res => res === 'Please fill in all required fields' || res === 'Missing comment');
            if (!err) {
                errors.push('At least one date field must have a valid value');
            }
        }
    }

    // Determine the overall validation condition
    const isValid = errors.length === 0;

    return { condition: isValid, error: errors.join(', ') };
}


  isValidDates(dateKey: string): boolean {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    return dateRegex.test(dateKey);
  }

  isValidValues(value: string): boolean {
    if (value == null) return true;
    const valueParts = value.split('|');
    if (valueParts.length !== 2) {
      return false;
    }

    const [numberPart, stringPart] = valueParts;

      if (numberPart == ''||numberPart == ' ') {
      return false;
    }

    // Validate the string part
    let hour: any = Number(numberPart) > 0
    if (hour && (typeof stringPart !== 'string' || stringPart.trim() === '')) {
      return false;
    }

    return true;
    // if (value === '' || value == null) return true; // Optional fields are valid if empty
    // const valueRegex = /^\d+(\.\d+)?\|.*$/; 
    // return valueRegex.test(value);
  }

 
  replaceNullsWithPipe(data: any[]): any[] {
    return data.map(item => {
      // Create a new object to avoid mutating the original item
      const newItem = { ...item };
  
      // Iterate over the properties of the item
      for (const key in newItem) {
        // Check if the key is a date field and the value is null
        if (key.match(/^\d{4}-\d{2}-\d{2}$/) && newItem[key] === null) {
          newItem[key] = '|';
        }
      }
  
      return newItem;
    });
  }

  isWeekend(dateStr: string): boolean {
    // Parse the input date string
    const date = new Date(dateStr);
    
    // Get the day of the week (0 = Sunday, 6 = Saturday)
    const day = date.getDay();
  
    // Return true if the day is Saturday (6) or Sunday (0), otherwise false
    return day === 0 || day === 6;
  }

  transformData(data: any,from?:string) {
    const timesheets: any = [];
    data.map((entry: any) => {
      const { task_key, jira_no,...dates } = entry;
      const hours_regular: any = [];
      const comments: any = [];
      const managerComments: any = [];
      const workTypKey:any = [];

      Object.keys(dates).forEach(date => {
        if (date.includes('-')) {
          const [hours, comment,WorkTypeKey,managerComment] = dates[date].split('|');
          hours_regular.push(hours.trim());
          comments.push(comment); // Standardizing comments to 'Test'
          from ? workTypKey.push(WorkTypeKey ? WorkTypeKey : 1001)  : workTypKey.push('');
          managerComments.push(managerComment);
        }
      });

      timesheets.push({
        task_key: String(task_key),
        jira_no: jira_no,
        work_typ_key:workTypKey.join(','),
        hours_regular: hours_regular.join(','),
        comments: comments.join('|'),
        manager_comments: managerComments.join('|'),
      })
    });
    return { timesheets }
  };

}
