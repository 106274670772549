import { Injectable } from "@angular/core";
import { LocalStorageService, SessionStorageService } from "angular-web-storage";
import { Auth } from "aws-amplify";

@Injectable({
  providedIn: "root",
})
export class SessionService {

  userName:any
  constructor(
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {
  }

  setUsername(username: any) {
    this.sessionStorageService.set("username", username);
  }
  
  setUsernameLocal(username: any) {
    this.localStorageService.set("username", username);
  }

  setUserDisplays(username: any) {
     
    this.sessionStorageService.set("userDisplay", username);
  }

  setUser(user: any) {
    this.sessionStorageService.set("user", user);

    let sessionData = {
      session_id: new Date().getTime(),
    };
    this.setLogSession(sessionData);
  }

  getUser(): any {
    return this.sessionStorageService.get("user");
  }

  getUsernameLocal(): any {
    try {
      let user = JSON.parse(
        this.localStorageService.get("user").storage.user
      );
      return user.fullname;
    } catch (err) {
      return this.localStorageService.get("username");
    }
  }

  getUsername(): any {
    try {
      let user = JSON.parse(
        this.sessionStorageService.get("user").storage.user
      );
      // return user.fullname;
      return user;
    } catch (err) {
      return this.sessionStorageService.get("username");
    }
  }

  setUserSession(userSession: any) {
    this.sessionStorageService.set("userSession", userSession);
  }

  getUserSession(): any {
    return this.sessionStorageService.get("userSession");
  }

  isLoggedIn(): boolean {
    return this.sessionStorageService.get("user") != null;
  }

  setUserRole(userRole: any) {
    this.sessionStorageService.set("userRole", userRole);
  }

  getUserRole(): any {
    return this.sessionStorageService.get("userRole");
  }

  // log session data methods

  setLogSession(logSessionData: any) {
    this.sessionStorageService.set("logSessionData", logSessionData);
  }

  getLogSession(): any {
    return this.sessionStorageService.get("logSessionData");
  }

  async getUserAttributes() {
    let auth:any = sessionStorage.getItem('authUser')
    auth = JSON.parse(auth)

    if(auth) return auth

    let authUser:any = await Auth.currentUserInfo();
    sessionStorage.setItem('authUser', JSON.stringify(authUser))
    // console.log(user)

    return authUser
  }
}
