import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ICellRendererParams } from 'ag-grid-community';
import { AlertDialogService } from 'src/app/services/alert-dialog.service';
import { HelpService } from 'src/app/services/help.service';
export interface User {
    jira_task_no: string;
    jira_no: string;
    jira_desc:string;
    project_key: number;
    project_name: string;
    brand_name: string;
    task_description: string|null;
}

@Component({
  selector: 'app-autocomplete-cell',
  template: `
    <!-- <mat-form-field class="example-full-width" appearance="outline" *ngIf="!total"> -->
    <input type="text"   [matTooltip]="tooltpdata()"   class="form-control" *ngIf="!total"   matInput [formControl]="inputControl" [matAutocomplete]="auto"  [readonly]="helpService.status === 'Submitted' || helpService.status=='Approved' || cloneDisable">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event.option.value)">
    <mat-option 
          *ngFor="let option of filteredOptions | async"
          [value]="option" 
        >
          <span [matTooltip]="option['jira_desc']" >{{ option[props.label] }}</span>
        </mat-option>
    </mat-autocomplete>
    <span *ngIf="total" style="font-size: 16px; font-weight: 900; color: #6c757de6;">{{inputControl}}</span>
  `,
  styles: [
    `
      .example-full-width {
        width: 100%;
      }
      .form-control {
    width: 100%;
    min-height: 35px;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 5px !important;
    color: #6c757de6;
    
    }
    ::ng-deep html {
    --mat-option-label-text-font: 'Poppins';
    --mat-option-label-text-line-height: 15px !important;
    --mat-option-label-text-size: 14px;
    --mat-option-label-text-tracking: 0.03125em;
    --mat-option-label-text-weight: 600;
    --mat-option-label-text-color: #28285e;
        // padding-left: 16px; 
        // padding-right: 16px;
}
::ng-deep .mat-mdc-option {
    min-height: 25px!important;
    padding-left: 7px !important; 
    padding-right: 1px !important;
 }
    `
  ]
})
export class AutocompleteCellRenderer implements OnInit, ICellRendererAngularComp {
  params: any;
  inputControl : FormControl = new FormControl();
  options: any;
  filteredOptions!:Observable<any[]>;
  total: boolean = false;
  public props: any;
  cloneDisable:boolean = false;
constructor(public AlertDialog: AlertDialogService,public helpService:HelpService){}
  agInit(params: any): void {
    this.cloneDisable = params.disabled;
      if (params.node.rowPinned) {
        this.inputControl=params.value
      this.total = true;
      return;
    }
    this.props = params.props || { value: 'jira_no', label: 'jira_task_no',desc:'jira_desc' };
    this.params = params;
    this.options = this.getFilteredOptions(this.params.rawData, this.params.filters, true);
    this.inputControl.setValue(( this.options.find((d:any)=>d.jira_no==this.params.value)?.[this.props.label])||'' );
    this.filteredOptions = this.inputControl.valueChanges.pipe(startWith(''),map(value => this._filter(value)));
  }

  refresh(params: ICellRendererParams<any, any, any>): boolean {
    this.params = params;
   if( this.params.value =="Grand Total "){return true}
   let filter = this.filterData(this.params.data, this.params.filters);
    this.options = this.getFilteredOptions(this.params.rawData, filter, true); //params.options || [];
    if (this.inputControl.value!=null||this.inputControl.value!=undefined) {
        this.inputControl.setValue(( this.options.find((d:any)=>d.jira_no==this.params.value)?.[this.props.label])||'' );
      }
       return true;
  }

  ngOnInit(): void {}

  getValue() {
    return this.inputControl.value;
  }
  onOptionSelected(user:any) {
      this.inputControl.setValue(user[this.props.label]);
      this.params.data[this.params.colDef.field] = parseInt(user ? user[this.props.value] : "");
      // console.log(user ? user[this.props.value] : ""  ,this.params.data[this.params.colDef.field]);
    if (this.params.colDef.field == 'jira_no') {
      // const allJiraNosSame = checkIfAllJiraNosSame(this.params.context.componentParent.rowData, parseInt(user ? user[this.props.value] : ""));
      const allJiraNosSame = hasDuplicateJiraNos(this.params.context.componentParent.rowData,this.params.node.id);
      if (allJiraNosSame) {
              this.params.data[this.params.colDef.field] = null;
              this.filteredOptions== of([]); 
              this.inputControl.setValue('');
              this.AlertDialog.error('Jira already selected')
      }
      function hasDuplicateJiraNos(data: any[], currentNodeId: any): boolean {
        const jiraNoSet = new Set<number>();
        for (const item of data) {
            // Skip the current row when checking for duplicates
            if (item.jira_no && item.jira_no !== null && item.jira_no !== undefined && item.nodeId !== currentNodeId) {
                if (jiraNoSet.has(item.jira_no)) {
                    return true;
                }
                jiraNoSet.add(item.jira_no);
            }
        }
        return false;
    }
    //  function checkIfAllJiraNosSame(data: any[], value: number): boolean {
    //     if (data.length === 1) return false;
    //     // const firstJiraNo: number = value;
    //     return data.every(item => item.jira_no === value);
    //   }
    
    }
    return '';
  }

  private _filter(value: string): any[] {
    const filterValue =(typeof value === 'string')? (value.toLowerCase() || ''):value
    return this.options.filter((option: any) =>
      option[this.props.label].toLowerCase().includes(filterValue) || option[this.props?.desc].toLowerCase().includes(filterValue)
    );
  }

  getFilteredOptions(data: any[], filters: { [key: string]: any }, condition: boolean): any[] {
    if (Object.keys(filters).length === 0) {
      return data;
    }
    return data.filter((option: any) => {
      return Object.keys(filters).every(key => option[key] === filters[key]);
    });
  }
  filterData(data: any, filter: any) {
    const filters = Object.keys(filter);
    const filteredData: any = {};
    filters.forEach(key => {
      if (data.hasOwnProperty(key)) {
        filteredData[key] = data[key];
      }
    });
    return filteredData;
  }

  tooltpdata(){
   let tooltip:any = this.options.find((d:any)=>d[this.props.label]==this.inputControl.value)?.['jira_desc']||undefined
    return  tooltip
  }
}
