

<mat-toolbar color="primary" class="header" style="background: linear-gradient(90deg, rgba(174,184,210,1) 0%, rgba(158,70,134,1) 54%, rgba(9,56,104,1) 100%)">
  <!-- Left side: Profile Name and Logout Menu -->
  <div class="left-section profile">
    <img src="assets/svg/all.svg" alt="Profile Picture" class="profile-img" />
    <span class="font-family">{{ userName }}</span>
    <button mat-icon-button [matMenuTriggerFor]="logoutMenu">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <!-- Logout Menu -->
    <mat-menu #logoutMenu="matMenu">
      <!-- <button mat-menu-item (click)="logout()" class="font-family">Logout</button> -->
      <button mat-menu-item (click)="logout()" ><span style="font-family: 'poppins' !important;">Logout</span></button>
    </mat-menu>
  </div>

  <!-- Right side: Menu Icon to Open Navigation List -->
  <span class="spacer"></span>
  <button style="color: white;" id="sidenav" *ngIf="!isBurgerMenu" mat-icon-button (click)="toggleSideNav()">
    <mat-icon>menu</mat-icon>
  </button>
</mat-toolbar>

<!-- Side Navigation -->
<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" position="end" [opened]="sideNavOpen" (closedStart)="closeSideNav()">
    <mat-nav-list>
      <a *ngFor="let link of navLinks"
        mat-list-item
        [routerLink]="link.disabled ? null : link.path"  
        [routerLinkActive]="['active']"
        [class.disabled]="link.disabled || isActiveRoute(link.path)"
        (click)="onLinkClick($event, link)">
        <img [src]="link.imageUrl" alt="{{ link.label }}" class="round-image">
        <span class="text-font font-family">{{ link.label }}</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>

