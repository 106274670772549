import { Component } from '@angular/core';
import { ManagerService } from 'src/app/services/manager-service.service';

@Component({
  selector: 'app-trainings',
  templateUrl: './trainings.component.html',
  styleUrls: ['./trainings.component.scss']
})
export class TrainingsComponent {

  headerTitle: boolean = true;

  videos:any[] = [];

  selectedVideo:any = "";
  selectedVideoPath = "";

  constructor(private managerService:ManagerService) { }

  ngOnInit(): void {
    this.getVideoListName();
  }
  playVideo(video:any) {
    this.selectedVideo = video;
  }

  getVideoListName() {
    this.managerService.getVideoLink().subscribe((res:any) => {
      //console.log(res)
      this.videos = res;
    })
  }
  errorMessage: string | null = null;
  currentVideoName:string = "";

  isMp4(file: string): boolean {
    return !!file && file.endsWith('.mp4');
  }
  
  isPdf(file: string): boolean {
    return !!file && file.endsWith('.pdf');
  }
  
  isPpt(file: string): boolean {
    return !!file && (file.endsWith('.ppt') || file.endsWith('.pptx'));
  }

  getSelectedVideo(video: any) {
    this.selectedVideo = "";
    this.selectedVideoPath = video;
    let payload = { "file_path": video };
    
    const filePath = this.selectedVideoPath;

    // Extract the filename
    const fileName = filePath.substring(filePath.lastIndexOf('/') + 1, filePath.lastIndexOf('.'));  // Output: "2. Welcome to Data Unveil-Business Overview (Session2)"

    // Remove the number and space at the start
    this.currentVideoName = fileName.replace(/^\d+\.\s*/, '');

    this.managerService.getSelectedVideo(payload).subscribe({
        next: (url: string) => {
           // console.log('Received URL:', url); // Log response URL
            this.selectedVideo = url;
            this.errorMessage = null; // Clear any previous errors
        },
        error: (error) => {
            console.error('Error fetching video URL:', error.error.text); // Detailed error log
           this.selectedVideo = error.error.text
        }
    });
}
}
