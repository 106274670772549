<div class="modal-overlay" (click)="close()"></div>
<div class="modal-content">
  <div *ngIf="isLoading">
    <div class="clone-loader-overlay">
      <div class="clone-loader"></div>
    </div>
  </div>
  <div style="padding-bottom: 10px;  display: flex;justify-content: center; ">
    <h4 style="font-weight: bold; font-family: 'poppins';"> &nbsp;Clone Timesheet</h4>
  </div>
  <!-- Close icon in the top right corner -->
  <mat-icon class="close-icon" (click)="close()">close</mat-icon>
  
    <form [formGroup]="dateRangeForm" style="float: right;" >
      <mat-form-field   style="margin-right: 200px;">
          <mat-label style="font-family: 'poppins';">Choose a Week</mat-label>
          <input matInput [matDatepickerFilter]="myCloneFilter"  [matDatepicker]="pickerclone" (dateChange)="onSubmitdate($event,pickerclone)"  formControlName="startDate" required>
          <mat-datepicker-toggle matIconSuffix [for]="pickerclone" ></mat-datepicker-toggle>
          <mat-datepicker #pickerclone >
          </mat-datepicker> 
      </mat-form-field>
    </form>

    <div class="mt-2" style="height: 60%;  width: '100%'; flex: '1 1 auto';">
      <ag-grid-angular 
          style="width: 100%; height: 100%; box-shadow: 0 0 2px rgba(0, 0, 0, 0.22);" 
          class="ag-theme-alpine clone-week-ag-grid" 
          *ngIf="rowData.length > 0"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [gridOptions]="gridOptions"
          [rowHeight]="45"
          [suppressDragLeaveHidesColumns]="true"
          [suppressContextMenu]="true"
          [suppressMenu]="true"
          (selectionChanged)="onSelectionChanged()"
          (gridReady)="onGridReady($event)"
          >
      </ag-grid-angular>
    </div>
  <div class="buttons" *ngIf="rowData.length > 0">
    <button type="submit" style="border-radius: 8px;" [ngClass]=" isButtonDisabled ? 'disabled-reject-cole': 'primary-btn-clone'"  (click)="cloneTimesheet()"   [disabled]="isButtonDisabled" >Clone</button>
  </div>
</div>

