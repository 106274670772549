import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ApiService } from 'src/app/services/api.service';
import { HelpService } from '../../../services/help.service';
import { AlertDialogService } from 'src/app/services/alert-dialog.service';

@Component({
  selector: 'app-mat-select-cell-renderer',
  template: `<select style="height: 20px;" [disabled]="helpService.status=='Submitted' || helpService.status=='Approved' || cloneDisable"  *ngIf="!total" class='form-control'  [(ngModel)]="selectedValue" (change)="onSelectionChange($event)">
        <option *ngFor="let option of options" [value]="option[props.value]" > {{ option[props.label] }}</option>
      </select>
      <span  *ngIf="total" style="font-size: 12px;font-weight: 600;color: #6c757de6;">{{selectedValue}}</span>
      
  `,
  styles: [`:host ::ng-deep {
   select {
    width: 100%;
    min-height: 35px;
    font-size: 12px;
    font-weight: 600;
    padding: 4px 5px !important;
    color: #6c757de6;
    
    }
}


  `]
})
export class MatSelectCellRendererComponent implements ICellRendererAngularComp {
  public options!: any[];
  public props: any;
  public selectedValue: any;
  private params: any;
  total: boolean = false
  cloneDisable:boolean = false;
  constructor(private apiService: ApiService, public helpService: HelpService, public AlertDialog: AlertDialogService) { }

  agInit(params: any): void {
    this.cloneDisable = params.disabled; // Handle disabled state
    this.selectedValue = params.value;
    if (params.node.rowPinned) { this.total = true; return }
    this.params = params;
    this.options = this.getFilteredOptions(this.params.rawData, this.params.filters, true);// params.options || [];
    this.props = params.props || { value: 'value', label: 'label' };
  }


  refresh(params: any): boolean {
    this.params = params;
    let filter = this.filterData(this.params.data, this.params.filters);
    this.options = this.getFilteredOptions(this.params.rawData, filter, true); //params.options || [];
    this.selectedValue = params.value;
    // this.cdRef.detectChanges();
    // console.log('Updated options:' + this.params.colDef.field, this.options);
    return true;
  }

  onSelectionChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
     
    this.params.data[this.params.colDef.field] = parseInt(value);
    this.params.context.componentParent.onCellValueChanged(this.params);
  }


  getFilteredOptions(data: any[], filters: { [key: string]: any }, condition: boolean): any[] {
    // Check if filters object is empty and return data unfiltered if it is
    if (Object.keys(filters).length === 0) { return data; }

    // Filter the data based on the provided filters
    return data.filter((option: any) => {
      return Object.keys(filters).every(key => option[key] === filters[key]);
    });
  }
  filterData(data: any, filter: any) {
    const filters = Object.keys(filter);
    const filteredData: any = {};
    filters.forEach(key => {
      if (data.hasOwnProperty(key)) {
        filteredData[key] = data[key];
      }
    });
    return filteredData;
  }


  
}
