<!-- <div class="header" ><span mat-dialog-title>Timesheet tracking</span></div> -->

<mat-dialog-content *ngIf="!managerOnly">
 
    <mat-label class="font-family" style="color: #101D42;">Task Details <mat-label class="font-falily" *ngIf="!isApprovalViewPresent">( Mandatory )</mat-label></mat-label>
    <!-- Close icon in the top right corner -->
  <mat-icon class="close-icon" [mat-dialog-close]="true" style="position: absolute; top: 8px; right: 8px; cursor: pointer;">close</mat-icon>
    <textarea  [disabled]="helpService.status=='Submitted' || helpService.status=='Approved'"  [minlength]="this.minlength"   [maxlength]="maxlength"  rows="4" cols="33" class="descriptionarea form-control" [(ngModel)]="value"  (ngModelChange)="onSelectionChange($event)"    placeholder="Enter Task Details"></textarea>
    <!-- (keypress)="validateText($event)" -->
   <!-- Work Mode Type with Flexbox for Alignment -->
  <div *ngIf="!fromClone" style="display: flex; align-items: center; margin-top: 16px;">
    <mat-label style="margin-right: 8px; font-weight: 600;">Work Mode :</mat-label>
    <select [disabled]="helpService.status=='Submitted' || helpService.status=='Approved'" style="width: 30%; height: 32px;" class="form-control" [(ngModel)]="selectedWorkMode" (change)="onSelectSelectionChange($event)">
      <option *ngFor="let option of workModeOptions" [value]="option.work_typ_key">{{ option.work_typ }}</option>
    </select>
  </div>
</mat-dialog-content>

<mat-dialog-content *ngIf="!managerOnly && (mangerValue !== '' || isApprovalViewPresent) ">
  <mat-label class="font-family" style="color: #101D42;">Rejection Reason <mat-label class="font-family" *ngIf="mangerValue !== '' || isApprovalViewPresent">( Mandatory )</mat-label></mat-label>
  <textarea  [disabled]="!isApprovalViewPresent"  [minlength]="this.minlength"   [maxlength]="maxlength"  rows="4" cols="33" class="descriptionarea form-control" [(ngModel)]="mangerValue"  (ngModelChange)="onMangerSelectionChange($event)"    placeholder="Enter Reason"></textarea>

</mat-dialog-content>
<mat-dialog-content *ngIf="managerOnly">
  <mat-label class="font-family" style="color: #101D42;">Rejection Reason <mat-label class="font-family" *ngIf="!isApprovalViewPresent">( Mandatory )</mat-label></mat-label>
  <mat-icon class="close-icon" [mat-dialog-close]="true" style="position: absolute; top: 8px; right: 8px; cursor: pointer;">close</mat-icon>
  <textarea [disabled]="!isApprovalViewPresent" rows="4" cols="33" class="descriptionarea form-control"  [(ngModel)]="managerDateComment" (ngModelChange)="onManagerDateSelectionChange($event)" placeholder="Enter Reason"></textarea>
</mat-dialog-content>

<mat-error class="font-family" *ngIf="value.length<this.minlength&&value.length>0 || mangerValue.length<this.minlength&&mangerValue.length>0" style="margin-left: 20px;">
    Minimum character is 15
  </mat-error>
  <mat-dialog-actions style="float: right;"  *ngIf="managerOnly">
    <div class="buttonsdiv">
       <button  class="okbut font-family" *ngIf="isApprovalViewPresent" (click)="onmanagerClick()"  [mat-dialog-close]="value">Ok</button>
        <button class="secondary font-family"  (click)="value=data" [mat-dialog-close]="true"  >Cancel</button>
      </div>
</mat-dialog-actions>
<mat-dialog-actions style="float: right;" *ngIf="!managerOnly">
    <div class="buttonsdiv">
       <button  class="okbut font-family" *ngIf="(helpService.status!='Submitted' && helpService.status!='Approved')|| isApprovalViewPresent" (click)="onNoClick()" [disabled]="isDisabled()" [mat-dialog-close]="value">Ok</button>
        <button class="secondary font-family"  (click)="value=data" [mat-dialog-close]="true"  >Cancel</button>
      </div>
</mat-dialog-actions>
