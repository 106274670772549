import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  private timeoutId: any;
  private readonly AUTO_LOGOUT_TIME = 20 * 60 * 1000; // 20 minutes in milliseconds

  constructor(private router: Router,private authService: AuthService) {
    this.init();
  }

  // Initialize the auto-logout logic
  init(): void {
    this.resetTimeout(); // Start the timer initially
    this.addActivityListeners(); // Listen to user activity
  }

  // Reset the logout timer
  resetTimeout(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.logoutUser(); // Logout the user after 20 minutes of inactivity
    }, this.AUTO_LOGOUT_TIME);
  }

  // Add event listeners to track user activity
  addActivityListeners(): void {
    const events = ['mousemove', 'click', 'keypress', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, () => this.resetTimeout());
    });
  }

  // Log out the user by redirecting to the login page
  logoutUser(): void {
    this.authService.logoutAndRedirect();
  }
}
