import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'app-time-tracking-dialog',
  templateUrl: './time-tracking-dialog.component.html',
  styleUrls: ['./time-tracking-dialog.component.scss']
})
export class TimeTrackingDialogComponent implements OnInit {
 
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<TimeTrackingDialogComponent>,public helpService :HelpService,private router: Router,  private activatedRoute: ActivatedRoute,) {}
value:any
mangerValue:any;
minlength:number=15
maxlength:number=400
managerOnly: boolean = false;
managerDateComment:any;
selectedWorkMode: any=1001; // Holds the selected work mode
workModeOptions:any;
fromClone:boolean= false;
  ngOnInit(): void {
    this.workModeOptions = this.helpService.getWorkMode();
   this.value= this.data.employeeCom;
   this.mangerValue= this.data.managerCom;
   this.managerOnly = this.data.managerOnly;
   this.managerDateComment = this.data.mangerDateCommnet,
   this.selectedWorkMode = this.data.workModeValue ? this.data.workModeValue : this.selectedWorkMode;
   this.fromClone = this.data.fromClone ? this.data.fromClone : this.fromClone;
   //console.log("this.mangerValue",this.data);
   
   this.getAprovalView();
  }

  onNoClick(): void {
    if(this.value.length>(this.minlength-1)||this.value.length==0){
      let value:any ={
        emploeeComment:this.value.trim(),
        managerComment:this.mangerValue.trim(),
        workModeValue:this.selectedWorkMode
      }
      this.dialogRef.close(value)//this.value.trim());
    }
  }

  onmanagerClick(): void {
    let value:any ={
      employeeDateComment:this.managerDateComment.trim(),
      date:this.data.date
      
    }
    this.dialogRef.close(value)
  }

  isApprovalViewPresent: boolean = false;
  getAprovalView() {
    let url = this.router.url;
    this.isApprovalViewPresent = url.includes('approval-view');
     // console.log("this.isApprovalViewPresent",this.isApprovalViewPresent);
  }
  onSelectionChange(event:any){
    this.value =event
  }

  onSelectSelectionChange(event: Event): void {
    const value = (event.target as HTMLSelectElement).value;
     this.selectedWorkMode = parseInt(value);
  }

  onMangerSelectionChange(event:any){
    this.mangerValue =event
  }

  onManagerDateSelectionChange(event:any) {
    this.managerDateComment =event
  }
  isDisabled(): boolean {
    return (this.value.length < this.minlength && this.value.length > 0 ) || this.selectedWorkMode === '';
  }

  validateText(event: KeyboardEvent) {
    const regex = /^[a-zA-Z0-9\s]*$/; // Allow only a-z,A-Z,0-9 and spaces
    const inputChar = String.fromCharCode(event.keyCode);
  
    if (!regex.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  }
}
