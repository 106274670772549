
<app-header></app-header>
<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container" style=" background-color: #e3e8f5;">
  <div style="padding-bottom: 10px;  display: flex;justify-content: center; ">
    <h4 class="label"> &nbsp; Timesheet :&nbsp;{{helpService.selectedDate}}</h4>
  </div>
  
  <div style="display: flex;justify-content: space-between;">

    <div style="display: flex; gap: 20px;">
  <div class="employee-details">
    <div class="detail">
      <span class="label">Employee Name :</span>
      <span class="value">{{EmpName}}</span>
    </div>
    <div class="detail">
      <span class="label">Employee Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
      <span class="value">{{empCode}}</span>
    </div>
    <div class="detail">
      <span class="label">Designation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
      <span class="value">{{designation}}</span>
    </div>
    <div class="detail">
      <span class="label">Status&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
      &nbsp;&nbsp;<span class="value"
      [ngClass]="{
        'new-status': helpService.status === 'New',
        'approved-status': helpService.status === 'Approved',
        'pending-status': helpService.status === 'Pending',
        'rejected-status': helpService.status === 'Rejected',
        'submitted-status': helpService.status === 'Submitted'
      }">{{helpService.status}}</span>
    </div>
   
  </div>


  <form [formGroup]="dateRangeForm" style="float: right;" >
    <mat-form-field  class="custom-height example-full-width" style="margin-right: 200px;">
      <mat-label style="font-family: 'poppins';">Choose a Week</mat-label>
      <input matInput [matDatepickerFilter]="myFilter"  [matDatepicker]="picker" (dateChange)="onSubmitdate($event,picker)"  formControlName="startDate" required>
      <mat-datepicker-toggle matIconSuffix [for]="picker" ></mat-datepicker-toggle>
      <mat-datepicker [dateClass]="dateClass"  #picker (opened)="onOpen()" panelClass="custom-datepicker">
      </mat-datepicker>
     
      
    </mat-form-field>
    <div style="display: none">
      <div class="datepicker-footer" #datepickerFooter>
        <div class="color-circles">
          <div class="circle" style="background-color: #E74028;"></div>
          <span class="cal-span-lg">Rejected</span>
          <div class="circle" style="background-color: #0934CC;"></div>
          <span class="cal-span-lg">Submitted</span>
          <div class="circle" style="background-color: #2CB239;"></div>
          <span class="cal-span-lg">Approved</span>
          <div class="circle" style="background-color: #FFB039;"></div>
          <span class="cal-span-lg">Pending</span>
        </div>
      </div>
  </div>
&nbsp;
    <!-- <button type="submit"  mat-raised-button color="primary" [disabled]="dateRangeForm.invalid">Get Week</button> -->

  </form>  
</div>
  <div style="display: flex;align-items: flex-end;  " >
    <button type="button" class="btn btn-primary btn-sm" *ngIf="helpService.status === 'New'"  style="margin-right: 11px;" (click)="openCloneWeekModal()"><span style="font-family: 'poppins';">Clone Week</span></button>
    <button type="button" class="btn btn-primary btn-sm" (click)="addRow()" *ngIf="showtable && (helpService.status != 'Submitted' && helpService.status != 'Approved')">
      <mat-icon class="icon-inline">add_circle</mat-icon>
      <span class="text-inline">Add Row</span>
    </button>
  </div>
</div>




    <div class="mt-2" style="height: calc(100vh - 300px);  width: '100%'; flex: '1 1 auto';">
      <ag-grid-angular 
          style="width: 100%; height: 100%; box-shadow: 0 0 2px rgba(0, 0, 0, 0.22);" 
          class="ag-theme-alpine overFlowAdd" 
          *ngIf="showtable"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [gridOptions]="gridOptions"
          [rowHeight]="45"
          [suppressDragLeaveHidesColumns]="true"
          [suppressContextMenu]="true"
          [suppressMenu]="true"
          (gridReady)="onGridReady($event)"
          (cellValueChanged)="onCellValueChanged($event)">
      </ag-grid-angular>
    </div>

  <div class="buttons" *ngIf="showtable && (helpService.status != 'Submitted' && helpService.status != 'Approved')">
    <button type="submit" class="secondary" style="border-radius: 8px;" (click)=" saveTimesheet(1)"   [disabled]="rowData.length==0" >Save</button>
    <button  type="submit"  class="submit" style="border-radius: 8px;" (click)="submitTimesheet(2)"  [disabled]="rowData.length==0">Save & Submit</button>
  </div>
  <span style="color: #8A98AB;font-size: 13px;">Last Updated on: {{ userModifiedDate | date: 'MM/dd/yyyy' }}</span>
</div>

<app-cloneweek
  *ngIf="isModalOpen" 
  [passToClone]="passToClone" 
  [dataSet]="dataSet"
  (selectedData)="receiveSelectedData($event)" 
  (closeModal)="closeModal()">
</app-cloneweek>