import { Component, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { DateFilterFn } from '@angular/material/datepicker';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { NavigationEnd, Router } from '@angular/router';
import { ColDef, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { filter, Subject, takeUntil } from 'rxjs';
import { ManagerService } from 'src/app/services/manager-service.service';

@Component({
  selector: 'app-managerview',
  templateUrl: './managerview.component.html',
  styleUrls: ['./managerview.component.scss']
})
export class ManagerviewComponent implements OnDestroy {

  headerTitle: boolean = true;
  isLoading: boolean = false;
  timesheetForm!: FormGroup;
  managerDetails: any;
  resources:any = [];
  private gridApi: any;
  private destroy$ = new Subject<void>();
  isButtonDisabled:boolean=true;
  @ViewChild('Selectedall') Selectedall!:  MatSelect;
  @ViewChild(MatSelect) matSelect!: MatSelect;


  constructor(private router: Router,private managerService: ManagerService) { }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
         event.url;
        //console.log("Current URL:", event.url);
      });
    this.timesheetForm = new FormGroup({
      resourceName: new FormControl([], Validators.required),
      weekSelection: new FormControl(),
    });
    this.getPreviousMonday();
    this.managerDetails = sessionStorage.getItem('EmployeeData');
    this.managerDetails = (JSON.parse(this.managerDetails))[0];
    //console.log("managerDetails", this.managerDetails);
    

    //this.getResourceListByManager();
    this.applyFilter()
  }

  applyFilter() {
    this.isLoading = !this.isLoading;
    let selectedResources = this.timesheetForm.value.resourceName;
    selectedResources = selectedResources.join(',');
   // this.matSelect.close();
    const payload = {
      
      "manager_key": this.managerDetails.employee_key,
      // "employee_key": selectedResources,
      // "week_start_date": this.fortmattedDate(this.timesheetForm.value.weekSelection)
    };

    this.managerService.getSubmittedTimesheetList(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (res: any) => {
        this.isLoading = !this.isLoading;
       // console.log("Submitted Timesheet List: ", res);
        if(res) {
          try {
            
           let data = res
           data.forEach((element: any) => {
             element.duration = this.getDurationDate(element.submitted_dt)
             element.manager_email = this.managerDetails.email    // add Email Rejection          
           })
            this.rowData = Array.isArray(data) ? data : [];
            
          } catch (error) {
            console.error('Error transforming data:', error);
            this.rowData = []; // Ensure rowData is cleared in case of an error
          }
        }
      }, (error) => {
        this.isLoading = !this.isLoading; // Ensure loading is set to false in case of an error
        console.error('Error fetching resources:', error);
      });
  }

  ngOnDestroy() {
    // Emit a value to complete all subscriptions
    this.destroy$.next();
    this.destroy$.complete();
  }
  fortmattedDate(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  columnDefs:ColDef[] = [
    { headerName: 'Resource Name',minWidth: 268,  field: 'emp_name' , 
      filter: true,
      suppressMovable: true,
      menuTabs: ['filterMenuTab'],
     
    },
    { headerName: 'Week Start Date',minWidth:200, headerClass: 'header-center-align',cellStyle: { textAlign: 'center' }, suppressMovable: false,suppressMenu: false,
      menuTabs: [],field: 'week_start_dt' ,valueFormatter: (params) =>{
      const dateStr = params.value; 
      return dateStr.slice(0, 10); // Extracts 'yyyy-mm-dd' directly from the string
    }},
    // { headerName: 'Timesheet Key',minWidth: 100, field: 'timesheet_key' },
    { headerName: 'Week End Date',minWidth:200,menuTabs: [],headerClass: 'header-center-align', field: 'week_end_dt' ,
      cellStyle: { textAlign: 'center' },
      sortable: true,         // Enable sorting
      sort: 'asc',            // Set default sort direction (asc or desc)
      valueFormatter: (params) => {
      const dateStr = params.value; 
      return dateStr.slice(0, 10); // Extracts 'yyyy-mm-dd' directly from the string
    }},
    { headerName: 'Total Hours',minWidth:50,menuTabs: [],headerClass: 'header-center-align',cellStyle: { textAlign: 'center' }, field: 'total_hours' },
    {headerName:'Submitted Date',minWidth:150,menuTabs: [],headerClass: 'header-center-align',cellStyle: { textAlign: 'center' }, field: 'submitted_dt',
    valueFormatter: (params) => {
      const dateStr = params.value; 
      return dateStr.slice(0, 10); // Extracts 'yyyy-mm-dd' directly from the string
    }},
    { headerName: 'No. of Days Pending Approval',minWidth:260,menuTabs: [],headerClass: 'header-center-align',cellStyle: { textAlign: 'center' }, field: 'duration' },
    { headerName: 'Select All', cellStyle: { textAlign: 'center' },
    menuTabs: [],
    minWidth: 175,
    maxWidth: 175,
    headerCheckboxSelection: true,
    checkboxSelection: true
    }

  ];

  rowData:any = [];

  defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex:1
  };

  public gridOptions: GridOptions = {
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    rowHeight: 35,
  };

  onGridReady(params: any) {
    this.gridApi = params.api;
  }

  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.isButtonDisabled = selectedRows.length === 0; // Disable button if no rows are selected
  }

  // Format date
  dateFormatter(params: any) {
    const date = new Date(params.value);
    return date.toLocaleDateString();
  }

  selectedData:any[] = [];
  submitTimesheet() {
    let selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedData = selectedNodes.map((node: any) => node.data);
    //console.log("selectedData",selectedData);
    // Set the selected data in the service
    this.managerService.setData(this.selectedData);
    
	  this.router.navigate(['/user/approval-view']);
  }

  mondayFilter: DateFilterFn<Date | null> = (date: Date | null): boolean => {
    if (!date) return false; // Handle the case where date is null
    return date.getDay() === 1; // 1 corresponds to Monday (0 = Sunday, 1 = Monday, etc.)
  }

  getPreviousMonday() {
    const today = new Date(); // Get today's date
    const day = today.getDay(); // Get the current day of the week (0 = Sunday, 1 = Monday, etc.)
    
    // Calculate the difference from today to the last Monday
    const diff = day === 0 ? 6 : day - 1; // Adjust if today is Sunday (0)
    
    // Get the current Monday
    const currentMonday = new Date(today);
    currentMonday.setDate(today.getDate() - diff);
  
    // Subtract 7 days to get the previous Monday
    const previousMonday = new Date(currentMonday);
    previousMonday.setDate(currentMonday.getDate() - 7);
    //By default set the week selection to previous monday
    this.timesheetForm.get('weekSelection')?.setValue(previousMonday);
  }
  // getResourceListByManager() {
  //   this.isLoading = !this.isLoading;
  //   const payload = {
  //     "manager_key": this.managerDetails.manager_key,
  //   };
  //   //get all resources of by manager
  //   this.managerService.getResourceList(payload)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((response: any) => {
  //       if (response) {
  //         this.resources = response;
  //        // this.filteredResources = this.resources;
  //         this.timesheetForm.get('resourceName')?.patchValue([...this.resources.map((item: any) => item.employee_key)]);
  //         this.isAllSelected = true;
  //         this.applyFilter();
  //       }
  //       this.isLoading = !this.isLoading; // Set loading to false after the API call completes
  //     }, (error) => {
  //       this.isLoading = !this.isLoading; // Ensure loading is set to false in case of an error
  //       console.error('Error fetching resources:', error);
  //   });
  // }
  
  isAllSelected: boolean = false;

  tosslePerOne() {
    
    // if(this.Selectedall.selected) {
    //  // this.Selectedall.deselect();
    //  // return false;
    //  this.isAllSelected = false;
    // }

    if(this.timesheetForm.get('resourceName')?.value.length === this.resources.length) {
     // this.Selectedall.select();
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
    return 
  }
  
 // This method will handle "Select All" functionality
  toggleAllSelection() {

    if(this.isAllSelected) {
      this.timesheetForm.get('resourceName')?.patchValue([...this.resources.map((item: any) => item.employee_key)]);
        //console.log("selected all",this.timesheetForm.get('resourceName')?.value);
    } else {
      this.timesheetForm.get('resourceName')?.patchValue([]);
    }
    // this.isAllSelected = !this.isAllSelected;
    // if(this.Selectedall.selected) {
    //   console.log("selected all",this.resources);
      
    //   this.timesheetForm.get('resourceName')?.patchValue([...this.resources.map((item: any) => item.employee_key)]);
    //   console.log("selected all",this.timesheetForm.get('resourceName')?.value);
      
    //   this.Selectedall.select();
    // } else {
    //   this.timesheetForm.get('resourceName')?.patchValue([]);
    //   this.Selectedall.deselect();
    // }
  }

  getDurationDate(date: any) {
   // const submittedDateStr = '2024-09-24T00:00:00';
    if(date==null || date=='' || date==undefined){
      return ''
      
    }
    // Convert the submitted date string to a JavaScript Date object
    const submittedDate:any = new Date(date);

    // Get the current date
    const currentDate:any = new Date();

    // Calculate the difference in time (in milliseconds)
    const timeDifference:any = currentDate - submittedDate;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysDifference
  }
  
}

