
<app-header></app-header>
<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container" style=" background-color: #e3e8f5;">
  <div style="margin-left: 40%;padding-bottom: 10px;">
    <h4 class="label"> &nbsp;Timesheet Approval</h4>
  </div>
  <div style="float: right;">
    <img 
      [src]="imagesrc" 
      alt="User Profile" 
      style="width: 75px; height: 75px; border-radius: 50%; cursor: pointer;"
    />
  </div>
  <div style="display: flex; gap: 20px;">
  <div class="employee-details">
    <div class="detail">
      <span class="label">Employee Name :</span>
      <span class="value">{{toApprovalData[currentIndex]?.emp_name }}</span>
    </div>
    <div class="detail">
      <span class="label">Employee Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :</span>
      <span class="value">{{ toApprovalData[currentIndex]?.employee_key}}</span>
    </div>
    <div class="detail">
      <span class="label">Designation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
      <span class="value">{{toApprovalData[currentIndex]?.designation}}</span>
    </div>
   
  </div>

</div>




   <div class="mt-2 approval-custom"  style="height:calc(100vh - 300px);">
     <ag-grid-angular style="width: 100%;box-shadow: 0 0 2px rgb(0 0 0 / 22%); " class="ag-theme-alpine overFlowAdd"
     *ngIf="showtable"
       [rowData]="rowData"
       [columnDefs]="columnDefs" 
       [gridOptions]="gridOptions"
       [rowHeight]="45"
       [suppressDragLeaveHidesColumns]="true"
       [suppressContextMenu]="true"
       [suppressMenu]="true"
       (gridReady)="onGridReady($event)" 
       >
       </ag-grid-angular>
       </div>

       <div class="table-container" *ngIf="showtable">
        <div class="content">
          <span class="last-updated">Last Updated on: {{ toApprovalData[currentIndex]?.submitted_date  | date: 'MM/dd/yyyy' }}</span>
          <div class="action-buttons buttons">
            <button type="submit" style="border-radius: 8px;" class=" approve" (click)="updateAndApproveTimesheet(3)" [disabled]="rowData.length==0">Approve</button>
            <button type="submit" style="border-radius: 8px;" class="reject"  (click)="updateAndApproveTimesheet(4)" >Reject</button>
            <!-- class="reject" || [ngClass]="!isApprovalViewPresent ? 'disabled-reject': 'reject'"  [disabled]="!isApprovalViewPresent" -->
            <div class="pagination-container">
              <span (click)="onFirstRecord()"><mat-icon>first_page</mat-icon></span>
              <span (click)="onPreviousRecord()"><mat-icon>chevron_left</mat-icon></span>
              <span class="page-indicator">{{ currentIndex + 1 }} of {{ approvalDataLength }}</span>
              <span (click)="onNextRecord()"><mat-icon>chevron_right</mat-icon></span>
              <span (click)="onLastRecord()"><mat-icon>last_page</mat-icon></span>
            </div>
          </div>
        </div>
      </div>
</div>