import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { TimeTrackingDialogComponent } from '../time-tracking-dialog/time-tracking-dialog.component';
import { AlertDialogService } from 'src/app/services/alert-dialog.service';
import { HelpService } from '../../../services/help.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mat-input-cell-renderer',
  template: `<div *ngIf="!total">
      <input type='text' [disabled]="helpService.status=='Submitted' || helpService.status=='Approved' " (keydown)="validateNumberInput($event)"  id="myInput"  [min]="0" [max]="8"  minlength="2" step=".25"  class="form-control" [(ngModel)]="value"  (ngModelChange)="onSelectionChange($event,dateField)" />
      <svg  [matTooltip]="popupdata"  [attr.ariaDisabled]="value=='' ? 'true' : 'false'" class='commentsvg' (click)="openDialog()" width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1875 13.75V15.9485C7.1875 16.1891 7.44792 16.3394 7.65625 16.2191L11.9329 13.75H15C15.6904 13.75 16.25 13.1904 16.25 12.5V5C16.25 4.30964 15.6904 3.75 15 3.75H5C4.30964 3.75 3.75 4.30964 3.75 5V12.5C3.75 13.1904 4.30964 13.75 5 13.75H7.1875ZM5 14.375C3.96447 14.375 3.125 13.5355 3.125 12.5V5C3.125 3.96447 3.96447 3.125 5 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V12.5C16.875 13.5355 16.0355 14.375 15 14.375H12.1004L7.96875 16.7604C7.34375 17.1213 6.5625 16.6702 6.5625 15.9485V14.375H5Z" fill="#8A98AB"/>
       <circle  *ngIf="(value !== '' && popupdata === '') || (value !== '' && popupdata !== '' && managerComment.length > 0)"  cx="14" cy="3" r="3" fill="red"/>
       <circle  *ngIf="value!=''&& popupdata.length>0 && managerComment.length == 0 "  cx="14" cy="3" r="3" fill="green"/>
      </svg></div>
      <span *ngIf="total" style="font-size: 14;font-weight: 600;color: #6c757de6;">{{value}}  <svg *ngIf="(isUserView && helpService.status === 'Rejected') || !isUserView"  [matTooltip]="this.helpService.getComments()[dateField]"  style="cursor: pointer !important;" (click)="openmanagerDateDialog(true)" width="24" height="24" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1875 13.75V15.9485C7.1875 16.1891 7.44792 16.3394 7.65625 16.2191L11.9329 13.75H15C15.6904 13.75 16.25 13.1904 16.25 12.5V5C16.25 4.30964 15.6904 3.75 15 3.75H5C4.30964 3.75 3.75 4.30964 3.75 5V12.5C3.75 13.1904 4.30964 13.75 5 13.75H7.1875ZM5 14.375C3.96447 14.375 3.125 13.5355 3.125 12.5V5C3.125 3.96447 3.96447 3.125 5 3.125H15C16.0355 3.125 16.875 3.96447 16.875 5V12.5C16.875 13.5355 16.0355 14.375 15 14.375H12.1004L7.96875 16.7604C7.34375 17.1213 6.5625 16.6702 6.5625 15.9485V14.375H5Z" fill="#8A98AB"/>
         <circle  *ngIf="( helpService.getComments()[dateField]!=='' && helpService.getComments()[dateField]!==undefined)"  cx="14" cy="3" r="3" fill="red"/>
       </svg></span>
  `,
  styles: [`
:host{
  div{
  display:flex;
  align-items: center;
    input {
      width: 70%;
      min-height: 30px !important;
      font-size: 12px;
      font-weight: 600;
      padding: 4px 5px !important;
      color: #6c757de6;
    }
  .form-control{
    // height: calc(1.5em + .75rem + 2px); 
  }
  .commentsvg{
    cursor: pointer;
  }
  .commentsvg[ariaDisabled="true"] {
    cursor: not-allowed !important;
}
}}
  `]
})
export class MatInputCellRendererComponent implements ICellRendererAngularComp {
  private params: any;
  public value: any='';
  total: boolean = false;
  popupdata: any = '';
  managerComment:any = '';
  toolTip:string = 'Task Details';
  managerTooltip:string = 'Manager Comment';
  dateField: string = '';
  isUserView:boolean = false;
  workModeValue:any;
  fromClone: boolean = false
  constructor(public dialog: MatDialog, public AlertDialog: AlertDialogService,public helpService: HelpService,private router: Router) {
    this.getCurrentUrl();
    if(!this.isUserView) this.helpService.setComment('','','approval');
   }
  agInit(params: ICellRendererParams): void {
     
    this.params = params;
    this.fromClone = this.params.fromClone
    //console.log("params input ", this.params, this.fromClone)
    if(params.value!=null&&params.value==undefined){return}
    if(this.params.data[this.params.colDef.field]==null){this.params.data[this.params.colDef.field]='|'}
    let val = this.params.data[this.params.colDef.field].split('|');
    this.value = val[0] == null ? '' : val[0];
    this.popupdata = (val[1] == undefined) ? '' : val[1];
    this.workModeValue = (val[2] == undefined || val[2] == null || val[2] == '') ? 1001 : val[2];
    this.managerComment = (val[3] == undefined) ? '' : val[3];
    if (params.node.rowPinned) { this.total = true }
    // this.params.data[this.params.colDef.field]=  this.value

    // Set the dateField for use in the template
    this.dateField = this.params.colDef.field; // Assign the date field
  }

  refresh(params: ICellRendererParams): boolean {
    // let val = params.value.split('|')
    // this.value = val[0] | 0;
    return true;
  }
  onSelectionChange(event: any,date:string): void {
    
    if (event == '') { event = ''  ; this.popupdata= ''; }
     
    // if (event > 15) { this.value = 0; alert('Hours allowed per day exceeded,Maximum is 15 hours') }
    let val =this.params.data[this.params.colDef.field]==null?'|': this.params.data[this.params.colDef.field].split('|')
    val[0] = event
    this.value = event
    this.params.data[this.params.colDef.field] = val[0] + '|' + ( val[0]==''? '':val[1])
    let value = this.params.context.componentParent.rowData.reduce((acc: any, row: any) => acc + (parseFloat(row[this.params.colDef.field].split('|')[0]) || 0), 0);
    if(this.helpService.isWeekend(date) ) {
      if( value > 8) {
        this.params.data[this.params.colDef.field] = '|'
        this.value = '';
        this.popupdata= '';
        this.AlertDialog.error('Hours allowed per weekend exceeded,Maximum is 8 hours')
        this.value = '';
        this.popupdata= '';
      }
    } else if (value > 9) {
      this.params.data[this.params.colDef.field] = '|'
      this.value = '';
      this.popupdata= '';
      this.AlertDialog.error('Hours allowed per day exceeded,Maximum is 9 hours')
      this.value = '';
      this.popupdata= '';
    }
    this.params.context.componentParent.onCellValueChanged(this.params);
  }

  openDialog(managerOnly: boolean = false): void {
   let dialogDataObject = {
      "employeeCom":this.popupdata,
      "managerCom":this.managerComment,
      "managerOnly": false,
      "mangerDateCommnet":this.managerDatecomment,
      "workModeValue":this.workModeValue,
      "fromClone": this.fromClone
    }
   if( !managerOnly && this.value==''){return}
    const dialogRef = this.dialog.open(TimeTrackingDialogComponent, {
      width: '400px',
      hasBackdrop: true,
      disableClose: true,
      data: dialogDataObject
    });

    dialogRef.afterClosed().subscribe((value) => {

      // console.log("value",value)
      if (value == true || value == undefined) { return }
      // console.log(value);
      let val = this.params.data[this.params.colDef.field].split('|')
      val[1] = value.emploeeComment;
      val[2] = value.workModeValue;
      val[3] = value.managerComment;
      //console.log("val[4],",val[4]);
      if(val[3] != undefined || val[3] != null || val[3] != '') this.helpService.changeData(true);
      this.popupdata = value.emploeeComment;
      this.workModeValue = value.workModeValue;
      this.managerComment = value.managerComment;
      if (val[3] === undefined || val[3] === null || val[3] === '') {
        this.params.data[this.params.colDef.field] = val[0] + '|' + val[1]+'|'+val[2];
      } else {
        if(val[4] === undefined || val[4] === null || val[4] === '') {

          this.params.data[this.params.colDef.field] = val[0] + '|' + val[1] + '|' + val[2]+'|'+val[3];
        } else {
          this.params.data[this.params.colDef.field] = val[0] + '|' + val[1] + '|' + val[2]+'|' + val[3]+'|'+val[4];
        }
       // console.log("this.params.data[this.params.colDef.field]", this.params.data[this.params.colDef.field]);
      }

      // Here you can trigger any actions based on the result
    });
  }

  managerDatecomment:any;
  managerCommentsByDate: any=[]; // Use an object instead of an array
openmanagerDateDialog(managerOnly: boolean = false): void {
  
  const dateField = this.params.colDef.field; // Date index field
  const existingManagerComment = this.helpService.getComments()[dateField] || ''; //manager comment for respective date

  let dialogDataObject = {
    employeeCom: this.popupdata,
    managerCom: this.managerComment,
    managerOnly: managerOnly,
    mangerDateCommnet: existingManagerComment,
    workModeValue:this.workModeValue,
    date: '',
  };

  const dialogRef = this.dialog.open(TimeTrackingDialogComponent, {
    width: '400px',
    hasBackdrop: true,
    disableClose: true,
    data: dialogDataObject
  });

  dialogRef.afterClosed().subscribe((value) => {
    if (value) {
     // console.log("Value received from dialog: ", value);
      
      // Check if the value contains the expected comment
      if (value.employeeDateComment) {
        // Store the manager comment for the specific date index
        this.helpService.setComment(dateField, value.employeeDateComment);
       // console.log("Updated managerCommentsByDate: ", this.helpService.getComments());
      } else {
       // console.log("No new comment to store for dateField: ", dateField);
      }
    } else {
     // console.log("Dialog closed with no value.");
    }
  });
}

  
  validateNumberInput(event: KeyboardEvent): void {
   let isWeekend = this.helpService.isWeekend(this.dateField);
    let validation:any = this.helpService.hoursvalidateData([this.params.data])
    if(validation.condition==false){
      this.value = '';
      this.popupdata= '';
      event.preventDefault();
    // console.log('Please fill in all required fields');
        this.AlertDialog.error('Please fill in all required fields')//+ validation.error)
     }
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];
    const numberRegex = /^[0-9.]$/;

    // Prevent number input if it's the weekend and the key is "9"
    if (isWeekend && event.key === '9') {
      this.AlertDialog.error('Hours allowed per weekend exceeded,Maximum is 8 hours')
      event.preventDefault();
      return;
    }
  
    if (!numberRegex.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
  
    // Ensure only one decimal point is allowed
    if (event.key === '.' && currentValue.includes('.')) {
      event.preventDefault();
    }
  }

  getCurrentUrl() {
    let url = this.router.url;
    //console.log("url", url);
    this.isUserView = url.includes('user-timesheet');
  }
  ngOnDestroy() { 
    this.helpService.changeData(false);
  }
  }
