<app-header [headerTitle]="headerTitle"></app-header>

<div class="main-container">
    <div class="left-video-list-div">
        <ul class="video-list">
            <li  [ngClass]="{'selected': selectedVideoPath === video.file_path}" *ngFor="let video of videos" (click)="getSelectedVideo(video.file_path)">
              {{ video.file_name }}
            </li>
        </ul>
    </div>
    <div class="right-video-div">
        <h4 style="text-align: center;">{{currentVideoName ? currentVideoName : ''}}</h4>
        <video *ngIf="selectedVideo" controls class="video">
            <source [attr.src]="selectedVideo" type="video/mp4">
            Your browser does not support the video tag.
        </video>

         <!-- Video -->
    <!-- Video (MP4) -->
    <!-- <video *ngIf="isMp4(selectedVideo)" controls class="media">
        <source [attr.src]="selectedVideo" type="video/mp4">
        Your browser does not support the video tag.
    </video> -->

    <!-- PDF -->
    <!-- <embed *ngIf="isPdf(selectedVideo)" [src]="selectedVideo" type="application/pdf" width="100%" height="600px" /> -->

    <!-- PowerPoint (PPT or PPTX) -->
    <!-- <iframe *ngIf="isPpt(selectedVideo)" 
            [src]="'https://docs.google.com/viewer?url=' + selectedVideo + '&embedded=true'" 
            width="100%" 
            height="600px" 
            allowfullscreen>
    </iframe> -->
    </div>
</div>