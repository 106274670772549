import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LogoutComponent } from './logout/logout.component';
import { AuthRoutingModule } from './auth.routing.module';



@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    FormsModule
  ],
  exports:[
    LoginComponent,
    ResetPasswordComponent,
    LogoutComponent
  ]
})
export class AuthModule { }
