<app-header [headerTitle]="headerTitle"></app-header>
<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>

<div class="container" style=" background-color: #e3e8f5;">
    <!-- <h2 class="header">Pending Approval Timesheet</h2> -->
    <div style="margin-left: 40%;padding-bottom: 10px;">
      <h4 class="label"> &nbsp;Pending Approvals</h4>
    </div>
     <!-- <div style="display: flex; gap: 20px;">
      <div class="employee-details">
        <div class="detail">
          <span class="label">Employee Name :</span>
          <span class="value">{{"Sandeep Sahoo - DU-1098"}}</span>
        </div>
        <div class="detail">
          <span class="label">Designation&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
          <span class="value">{{'Developer'}}</span>
        </div>
       
      </div>
     </div> -->
   
      
      <!-- grid table data -->
      <div class="ag-theme-alpine manger-custom-grid" style="height: auto; width: 100%;">
        <ag-grid-angular
          style="width: 100%; box-shadow: 0 0 2px rgb(0 0 0 / 22%);"
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          rowSelection="multiple"
          domLayout="autoHeight"
          [gridOptions]="gridOptions"
          (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged()">
        </ag-grid-angular>
      </div>
      
      <!-- Submit Button (Right-aligned) -->
      <div style=" display: flex;
        justify-content: flex-end; margin: 20px 0; font-family: 'poppins';">
        <button mat-raised-button style="border-radius: 8px;" [ngClass]=" isButtonDisabled ? 'disabled-reject': 'primary-btn-clr'" color="#0934CC" (click)="submitTimesheet()" [disabled]="isButtonDisabled">Review</button>
      </div>
  </div>
  