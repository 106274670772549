export const environment = {
  production: false,
  apiBaseUrl:  'https://platform-stage-api.dataunveil.com/',
  AMPLIFY: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_IBRNnm00y',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    userPoolWebClientId: '2oubd6j8aae431u2disld0fnge',
  },
};
