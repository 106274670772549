import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  
  allOnLoadDataForFields:any = {};

  constructor(
   private apiService: ApiService
  ) {};

  getFilterFieldsData(method: string, url: string, parameter: string, key:any) {
 
    if (method.toUpperCase() == "POST") {
      let request: any = {};
 
      let keys = parameter.split(",");
 
      for (let k of keys) {
        let keyName = k;
        
        if(this.allOnLoadDataForFields[keyName] instanceof Array) {
          request[keyName] = this.allOnLoadDataForFields[keyName] ? this.getFilter(this.allOnLoadDataForFields[keyName], keyName) : "";
        } else {
          request[keyName] = this.allOnLoadDataForFields[keyName] ? this.allOnLoadDataForFields[keyName][keyName] : "";
        }

        let firstKey = request[keyName].split(',');
        request[keyName] = firstKey[1];
      }
 
      request = Object.assign({}, request);
 
      return this.apiService.post(url, request);
    } else {
      return this.apiService.get(url);
    }
  }

  getFilter(data: any[], paramName: any) {
    if (data.length == 0) return null;
    let filter = "";
    data.map((e) => {
      filter += e[paramName] + ",";
    });
    return filter.slice(0, filter.lastIndexOf(","));
  }
}
