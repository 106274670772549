import { Component, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { AlertDialogService } from 'src/app/services/alert-dialog.service';
import { ApiService } from 'src/app/services/api.service';
import { HelpService } from 'src/app/services/help.service';
import { ManagerService } from 'src/app/services/manager-service.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { MatInputCellRendererComponent } from '../user-timesheet/mat-input-cell-renderer';
import { CustomHeaderComponent } from '../user-timesheet/custom-header.component';
import { MatSelectCellRendererComponent } from '../user-timesheet/mat-select-cell-renderer';
import { AutocompleteCellRenderer } from '../user-timesheet/mat-autocomplete-cell-render';
import * as _ from 'lodash';
import { TimeTrackingDialogComponent } from '../time-tracking-dialog/time-tracking-dialog.component';

@Component({
  selector: 'app-approvaltimesheet',
  templateUrl: './approvaltimesheet.component.html',
  styleUrls: ['./approvaltimesheet.component.scss']
})
export class ApprovaltimesheetComponent {
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public grandTotalRow: "top" | "bottom" = "bottom";
  total: number | undefined;
  private gridApi!: GridApi;
  public gridOptions: GridOptions;
  dataSet: any;
  today: Date = new Date();
  empDetails: any;
  EmpName: string = '';
  designation: string = '';
  ClientData: any = [];
  fields: any = [];
  transformedClients: any;
  columnDefs!: ColDef[];
  rowData: any = []
  selectedweeks: any
  timeSheetMasterData: any
  dateRangeForm: FormGroup;
  endDate: any
  showtable: boolean = false
  firstChange: boolean = true;
  isLoading = false;
  initRowData:any=[]
  previousValue:any;
  isApprovalViewPresent: boolean = false;
  toApprovalData:any[]=[];
  approvalStart_wk:string = '';
  approvalEnd_wk:string = '';
  private destroy$ = new Subject<void>();
  managerComments:string='';
  currentIndex: number = 0; // Start with the first record
  approvalDataLength: number = 0;
  imagesrc: any;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    let navigate = this.helpService.arraysEqual(this.initRowData,this.rowData)
    if(!navigate && this.helpService.status!='Submitted'){
      const message = 'You have unsaved changes. Do you really want to leave?';
      $event.returnValue = message;
    }
  }
  constructor(private timesheetService: TimesheetService, private fb: FormBuilder, private router: Router,
    private apiService: ApiService, public helpService: HelpService, public AlertDialog: AlertDialogService, private activeroute: ActivatedRoute,
    private managerService:ManagerService,public dialog: MatDialog, private alertDialog: AlertDialogService) {
    this.isLoading = true
    this.apiService.getDoc('timesheet-fields').subscribe((res: any) => {
      this.fields = res ? res : [];
      this.loadFields(this.fields);
    })
    this.dateRangeForm = this.fb.group({
      startDate: [null, Validators.required]
    });


    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        minWidth: 100,
        editable: false,
        resizable: true,
        suppressHeaderMenuButton: true,
        // headerClass: 'text-center',
       
      },
      context: { componentParent: this },

    };

  }
  status:any =  [
    {
      "status_key": 1,
      "status_desc": "Pending"
    },
    {
      "status_key": 2,
      "status_desc": "Submitted"
    },
    {
      "status_key": 3,
      "status_desc": "Approved"
    },
    {
      "status_key": 4,
      "status_desc": "Rejected"
    }
  ]
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }


  ngOnInit(): void {

    this.helpService.currentData.subscribe((updatedValue) => {
      this.isApprovalViewPresent = updatedValue;  // Update local boolean value
      
    });
    setTimeout(() => {

      this.empDetails = sessionStorage.getItem('EmployeeData');
      this.empDetails = JSON.parse(this.empDetails)[0];
      this.EmpName =
        this.empDetails.emp_frst_nm +
        '' +
        this.empDetails.emp_last_nm +
        ' -  ' +
        this.empDetails.employee_key;
      this.designation = this.empDetails.designation;
    }, 5000);
  }
  getRowData(postdata: any) {
    this.getUserImage(postdata.employee_key);
    this.managerService.getManagerTimesheet(postdata).subscribe((res) => {
      this.rowData = res ? res : []
//      console.log("approval view first getRowData", this.rowData);
       this.initRowData = res ? _.cloneDeep(this.helpService.replaceNullsWithPipe(res)) : []; // Use lodash's cloneDeep
      // if(this.rowData.length==0){this.addRow()}
      this.getColumnDef(this.dataSet);
      this.isLoading = false;

    })
    this.showtable = true

    this.helpService.status='Submitted';
  }
  
  getUserImage(emp_key:string) :void {
    let payload = {"employee_key":emp_key}
    this.managerService.getEmployeeHeadshot(payload).subscribe({
      next:(url:string)=>{

        this.imagesrc = url
      },
      error:(err:any)=>{
        console.log(err)
        //this.imagesrc = err.error.text
      }
    })
  }

  getColumnDef(event: any) {

    let selectedweeks: any = sessionStorage.getItem('selectedDate');
    selectedweeks = JSON.parse(selectedweeks);

    //console.log("startDate: ", selectedweeks.week_start_dt, "endDate: ", endDate);
    const startDate = new Date( this.approvalStart_wk );
    const endDate = new Date( this.approvalEnd_wk );
    // Initialize an array to hold the dynamically created objects
    const columns = [];
    // Loop through each date from startDate to endDate
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'short' });
      const dayName = date.toLocaleDateString('en-US', { weekday: 'short' }); //get day name
      console.log(month);
      // Create the object for the current date
      const column = {
        headerName: `${dayName}, ${day} ${month} `,
        wrapHeaderText: true,
        field: moment(date).format('YYYY-MM-DD'),
        minWidth: 85,
        maxWidth: 85,
        aggFunc: "sum",
        // editable:true
        cellRenderer: MatInputCellRendererComponent,
      };

      // Add the object to the columns array
      columns.push(column);
    }

    this.columnDefs = [
      {
        headerName: 'Client Name',
        minWidth: 120,
        field: 'client_key',
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.client_key,
          filters: {},
          props: {
            value: "client_key",
            label: "client_nm"
          }
        }),
      },
      {
        headerName: 'Brand Name',
        field: 'brand_key',
        minWidth: 130,
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.brand_key,
          filters: {
            'client_key': params?.data?.client_key,
          },
          props: {
            value: "brand_key",
            label: "brand_nm"
          }
        })
      },
      {
        headerName: ' Project Name',
        field: 'project_key',
        minWidth: 150,
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.project_key,
          filters: {
            'client_key': params?.data?.client_key,
            'brand_key': params?.data?.brand_key,
          },
          props: {
            value: "project_key",
            label: "project_nm"
          }
        }),
      },
      {
        headerName: 'Task Name',
        field: 'task_key',
        minWidth: 170,
        headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          rawData: event.task_key,
          filters: {
            'client_key': params?.data?.client_key,
            'brand_key': params?.data?.brand_key,
            'project_key': params?.data?.project_key,
          },
          props: {
            value: "task_key",
            label: "task_description"
          }
         
        }),
      },
      {
        headerName: 'Jira #',
        field: 'jira_no',
        minWidth: 90,
        headerComponent: CustomHeaderComponent,
        cellRenderer: AutocompleteCellRenderer,
        cellRendererParams: (params: any) => ({
          rawData: event.jira_no,
          filters: {
            'project_key': params?.data?.project_key,
          },
          props: {
            value: "jira_no",
            label: "jira_task_no",
            desc:'jira_desc'
          }
        }),
      },
      ...columns,
      {
        headerName: 'Total',
        field: 'Totals',
        minWidth: 75,
        maxWidth:75,
        cellStyle: { 'text-align': 'center', 'font-size': '14', 'font-weight': '600', 'color': '#6c757de6' },
        cellRenderer: (params: any) => {
          let total = 0;
          Object.keys(params.data).forEach((key) => {
            if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
              const value = params.data[key]; // Get the value for the current date field
              if (value) { // Check if value is not null
                const numericValue = parseFloat(value.split('|')[0]); // Use parseFloat
                if (!isNaN(numericValue)) {
                  total += numericValue; // Add to total
                }
              }
            }
          });
          
          return total; // Return total to display in the cell
        }
         
        }
    ];

    // console.log(this.columnDefs)
    if(this.rowData.length>0) 
    this.addGrandTotalRow(this.gridApi);
    this.getTaskDescNo(this.rowData);
  // }

    this.isLoading = false
  }
  addGrandTotalRow(gridApi: any) {
//    console.log("grand",gridApi);
    
    if(gridApi) {
      const totalRow: any = { client_key: '', brand_key: '', project_key: '', task_key: '', jira_no: 'Total ', Totals: 0 };
     const val = ( moment(this.approvalStart_wk).format('YYYY-MM') );
     const val2 = ( moment(this.approvalEnd_wk).format('YYYY-MM') );
      
      this.columnDefs.forEach((colDef: ColDef) => {
        const field = colDef.field;
        if (field && field.startsWith(val)) {
          totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat((row[field]?.split('|')[0])) || 0), 0);
          totalRow[field] = totalRow[field] + '|';
          totalRow['Totals'] += parseFloat(totalRow[field]);
        }
        if (field && (val!=val2) && field.startsWith(val2)) {
          totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat((row[field]?.split('|')[0])) || 0), 0);
          totalRow[field] = totalRow[field] + '|';
          totalRow['Totals'] += parseFloat(totalRow[field]);
        }
      });
      console.log("total ", totalRow);
      
      gridApi.setPinnedBottomRowData([totalRow]);
      // gridApi.setGridOptions({ pinnedBottomRowData: [totalRow] });
    }
  }

  updateGrandTotalRow(gridApi: any) {
    const totalRow: any = { client_key: '', brand_key: '', project_key: '', task_key: '', jira_no: 'Total', actions: 0 };
    const rowData: any = [];
    const val = moment(this.selectedweeks.week_start_dt).format('YYYY-MM')
    const val2 = moment(this.selectedweeks.week_end_dt).format('YYYY-MM');

    gridApi.forEachNode((node: any) => rowData.push(node.data));
    this.columnDefs.forEach((colDef: ColDef) => {
      const field = colDef.field;
      if (field && field.startsWith(val)) {
        totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat(row[field].split('|')[0]) || 0), 0);
        totalRow[field] = totalRow[field] + '|'
        totalRow['actions'] += parseFloat(totalRow[field])
      }
      if (field && (val!=val2) && field.startsWith(val2)) {
        totalRow[field] = this.rowData.reduce((acc: any, row: any) => acc + (parseFloat((row[field]?.split('|')[0])) || 0), 0);
        totalRow[field] = totalRow[field] + '|';
        totalRow['actions'] += parseFloat(totalRow[field]);
      }
    });
    gridApi.setPinnedBottomRowData([totalRow]);
  }


  async loadFields(fields: any) {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];
      let opt = element.templateOptions;

      if (opt.dataSourceUrl) {
        await this.getDataForAllFields(opt.method, opt.dataSourceUrl, opt.parameter, element.key);
      }
    }

    // console.log("log", this.timesheetService.allOnLoadDataForFields);
    this.dataSet = this.timesheetService.allOnLoadDataForFields;
    this.isLoading = false
     this.onApprovalView();
  }
  taskDescRes:any = [];
  jirataskNo:any = [];
  async getDataForAllFields(method: string, endPoint: string, parameter: string, key: string) {
    return new Promise((resolve, reject) => {
      this.timesheetService.getFilterFieldsData(method, endPoint, parameter, key).subscribe(
        (res: any) => {
          if (res) {
//            console.log("key",key);
            
            this.timesheetService.allOnLoadDataForFields[key] = res;
            if(key=='work_typ_key') this.helpService.setWorkMode(res)
            if(key == "task_key"){
              this.taskDescRes = res;
            }
            if(key == "jira_no"){
              this.jirataskNo = res;
            }
            
          }
          resolve(res);
        },
        (error: any) => {
          this.AlertDialog.error(JSON.stringify(error))
          this.isLoading=false
          reject(error);
        }
      );
    });
  }


// on ManagerView once promise solve call the function
onApprovalView() {
  this.managerService.getData().subscribe(data => {
    this.toApprovalData = data;
   // console.log("this.toApprovalData",this.toApprovalData);
    
    this.approvalDataLength = this.toApprovalData.length; //get total no of employees record
    this.loadApprovalData(this.currentIndex); //By-default load the first record
  });
}

 // Load data for a given index
 loadApprovalData(index: number) {
  this.isLoading = true;
  if (this.toApprovalData[index]) {
    this.approvalStart_wk = moment(this.toApprovalData[index].week_start_dt).format('YYYY-MM-DD');
    this.approvalEnd_wk = moment(this.toApprovalData[index].week_end_dt).format('YYYY-MM-DD');
    let payload = {
      "employee_key": this.toApprovalData[index].employee_key,
      "week_start_dt": this.approvalStart_wk,
      "week_end_dt": this.approvalEnd_wk
    };

    if (this.dataSet) this.getRowData(payload);
  }
}

transformData(data: any) {
  const timesheets: any = [];
  data.map((entry: any) => {
    
    const { task_key, jira_no, ...dates } = entry;
    const hours_regular: any = [];
    const comments: any = [];
    const managerComments: any = [];
    const timesheet_detail_key:any = [];
    const workTypKey:any = [];

    Object.keys(dates).forEach(date => {
      
      if (date.includes('-') && dates[date]) {
        const [hours, comment,WorkTypeKey, managerComment, timesheet_detail] = dates[date].split('|');
        //console.log("timesheet_detail", timesheet_detail);
        
        hours_regular.push(hours?.trim() || '');
        comments.push(comment || '');
        workTypKey.push(WorkTypeKey)
        managerComments.push(managerComment || '');
        timesheet_detail_key.push(timesheet_detail || '');
      }
    });

    timesheets.push({
      task_key: String(task_key),
      jira_no: jira_no,
      hours_regular: hours_regular.join(','),
      comments: comments.join('|'),
      work_typ_key:workTypKey.join(','),
      manager_comments: managerComments.join('|'),
      timesheet_detail_key: timesheet_detail_key.join('|'),
      TASK_DESCRIPTION:this.selectedTaskDescription,
      JIRA_TASK_NO:this.selectedJiraNo,
    })
  });
  return { timesheets }
};


// Go to the next record
onNextRecord() {
  if (this.currentIndex < this.toApprovalData.length - 1) {
    this.currentIndex++;
    this.loadApprovalData(this.currentIndex);
  }
}
// Go to the previous record
onPreviousRecord() {
  if (this.currentIndex > 0) {
    this.currentIndex--;
    this.loadApprovalData(this.currentIndex);
  }
}

onFirstRecord() {
  if(this.currentIndex !== 0) {
    this.currentIndex = 0;
    this.loadApprovalData(this.currentIndex);
  }
}

onLastRecord() {
  if(this.currentIndex !== this.toApprovalData.length - 1) {
    this.currentIndex = this.toApprovalData.length - 1;
     this.loadApprovalData(this.currentIndex);
  }
}

updateAndApproveTimesheet(status:any) {
  
  this.timeSheetMasterData = sessionStorage.getItem('timeSheetMasterData');
      this.timeSheetMasterData = JSON.parse(this.timeSheetMasterData);
      
      let timesheetsData:any = this.transformData(this.rowData)
      
      
      let postData: any = {
        ...timesheetsData,
        "week_start_dt": this.approvalStart_wk  || this.selectedweeks.week_start_dt,
        "week_end_dt": this.approvalEnd_wk || this.selectedweeks.week_end_dt,
        "email":this.toApprovalData?.[this.currentIndex]?.email,
        "employee_key": this.toApprovalData[this.currentIndex].employee_key || this.selectedweeks.employee_key,
        "timesheet_key":this.toApprovalData[this.currentIndex]?.timesheet_key || null,//27,
        "timesheet_nm": this.toApprovalData?.[this.currentIndex]?.timesheet_nm || "Timesheet for Week " + moment( this.approvalStart_wk).format('MMMDD-') + moment(this.approvalEnd_wk).format('MMMDD'), //"Timesheet for Week Jun17-Jun21",
        "status_key": status,
        "emp_name" :this.toApprovalData?.[this.currentIndex]?.emp_name,
        "manager_email":this.toApprovalData?.[this.currentIndex]?.manager_email,
        "comments":this.getWeekupdate()
  
      }

      let original = this.helpService.arraysEqual(this.initRowData,this.rowData);
      // Get the comments from getWeekupdate
      // Check if any week comment is non-empty
      const weekComments = postData.comments.split('|');
      const hasAnyWeekComment = weekComments.some((comment: string) => comment.trim() !== '');

      // Check if any timesheet comment is non-empty
      let hasAnyValidComment = false;
      timesheetsData.timesheets.forEach((element: any) => {
        const comments = element.manager_comments.split('|');

        // Check if any of the comments are non-empty
        const hasValidComment = comments.some((comment: string) => comment.trim() !== '');

        if (hasValidComment) {
          hasAnyValidComment = true;
          return;  // Exit the loop if a valid comment is found
        }
      });

      let hasanyPreviousComments = false;
        let initialTimeSheetData:any = this .transformData(this.initRowData);
        initialTimeSheetData.timesheets.forEach((element: any) => {
          const initialComments = element.manager_comments.split('|');
          const hasInitialValidComment = initialComments.some((comment: string) => comment.trim() !== '');
          if(hasInitialValidComment){ 
            hasanyPreviousComments = true;
            return; 
          }
        })
      
      if(status == 3){ // Approval case
        if (!hasAnyValidComment && !hasAnyWeekComment) {
          this.updateApproveRejectTimesheet(postData); // Call the API if both 'hasAnyValidComment' and 'hasAnyWeekComment' are false
          return;  
        } else {
          // Show alert if any of the conditions are not met
          // this.alertDialog.confirmationWindow("Comment exists. Do you still want to Approve ?")
          // .then(() => {
          //   this.updateApproveRejectTimesheet(postData); //if confirm call the api
          // });
          this.alertDialog.warning(`Comment(s) exists. Please remove the Manager's comment to Approve.`)
          return;  
        }
      } else if(status == 4){ // Rejection case
        if (!original && (hasAnyWeekComment || hasAnyValidComment)) {
          this.updateApproveRejectTimesheet(postData); // Call the API if 'original' is false and 'hasAnyWeekComment' or 'hasAnyValidComment' is true
          return; 
        } else if (original && hasAnyWeekComment) {
          this.updateApproveRejectTimesheet(postData); // Call the API if 'original' is true and 'hasAnyWeekComment' is also true
          return;
        } else {
          // Show alert if 'original' is true but 'hasAnyWeekComment' is false
          if(hasanyPreviousComments){
            this.AlertDialog.warning(`Update the existing comment(s) or add new to Reject.`);
            return 
          } else {
            this.AlertDialog.warning(`Please add at least one manager comment or provide a day level comment.`);
             return;  // This should prevent the API call
          }
        }
      } 
      this.updateApproveRejectTimesheet(postData);
      // if(status == 3){
      //   console.log("status",status,"navigate", original);
      //   if(!original || hasAnyWeekComment){ 
      //     this.alertDialog.confirmationWindow("Comment exists. Do you still want to Approve ?")
      //     .then(() => {
      //       this.updateApproveRejectTimesheet(postData);
      //     });
      //     return ;
      //   }
      // }

      // if(status == 4){
        

      //   // If neither condition is met, show an error
      //   if ( !hasAnyValidComment && !hasAnyWeekComment) {
      //     this.AlertDialog.warning(`Please add at least one manager comment or provide a weekly comment.`);
      //     return;  // This should prevent the API call
      //   }
      // }

     // this.updateApproveRejectTimesheet(postData);
}

updateApproveRejectTimesheet(postData:any): void {
  this.isLoading = true;
      this.managerService.updateAndRejectTimesheet(postData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if(res) {
          this.AlertDialog.success("Timesheet"+ " "+(postData.status_key==3?"Approved":"Rejected")+" " +"successfully");
          
          this.toApprovalData.splice(this.currentIndex, 1)
          this.approvalDataLength = this.toApprovalData.length;
          this.currentIndex = 0;
          if(this.approvalDataLength == 0) this.router.navigate(['/user/manager-view']);
          this.loadApprovalData(this.currentIndex);
          this.isLoading = false;
        }
        (err:any) => {
          // Handle the error here
          this.isLoading = false
          this.AlertDialog.error('Error saving timesheet:' +JSON.stringify(err))
          console.error('Error saving timesheet:', err);
        }
        
      });
}

alertrowdata(){
var obj = {data:this.rowData};
alert(JSON.stringify(obj,null, 4));}
selectedTaskDescription:any;
selectedJiraNo:any;
getTaskDescNo(data: any) {
//  console.log("jira no", this.jirataskNo);
  
  // Initialize selectedTaskDescription as an array to hold multiple values
  let descriptions: string[] = [];
  let jiraNo:string[] = [];

  // Use forEach to iterate through data
  data.forEach((element: any) => {
    // Check for the corresponding task description
    const matchedTask = this.taskDescRes.find((element1: any) => {
      const match = element.task_key === element1.task_key;
      if (match) {
        console.log("Match found:", element.task_key);
      }
      return match;
    });


    
    // If a match is found, push the task description to the array
    if (matchedTask) {
      console.log("Matched Task Object:", matchedTask);
      if (matchedTask.task_description) {
        descriptions.push(matchedTask.task_description); // Add description to array
      }
    }

    const matchedJira = this.jirataskNo.find((element2:any)=>{
      const match1 = element.jira_no == element2.jira_no;
      if (match1) {
        console.log("Match found:", element.jira_no);
      }
      return match1
    })

    if(matchedJira){
      if(matchedJira.jira_task_no){
        jiraNo.push(matchedJira.jira_task_no)
      }
    }
  });

  // Join the descriptions with '|' separator and assign to selectedTaskDescription
  this.selectedTaskDescription = descriptions.join('|');
  this.selectedJiraNo = jiraNo.join('|');
  console.log("Final selectedTaskDescription", this.selectedTaskDescription,this.selectedJiraNo);
}

 getWeekupdate() {
  const weekArray: string[] = [];
  const startDate = new Date(this.approvalStart_wk);
  const endDate = new Date(this.approvalEnd_wk);
  
  // Create the week array with formatted dates
  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    weekArray.push(formattedDate);
  }
  
  // Fetch the comments
  const comments = this.helpService.getComments();
  
  // Create an array to hold values or empty strings
  const valuesArray = weekArray.map(date => {
    return comments[date] ? comments[date] : ''; // Assign comment value or empty string
  });

  // Return the values joined by ' | '
  return valuesArray.join('|');
 }


ngOnDestroy() {
  // Emit a value to complete all subscriptions
  this.destroy$.next();
  this.destroy$.complete();
}



}
