import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) {}

  getDoc(url: string): Observable<any> {
    return this.httpClient.get(`assets/json/${url}.json`)
  }

  get(url: string): Observable<any> {
    return this.httpClient.get(`${environment.apiBaseUrl}${url}`)
  }

  post(url: string, data: any): Observable<any> {
   return this.httpClient.post(`${environment.apiBaseUrl}${url}`, data)
  }
 
  getClient(): Observable<any> {
    return this.httpClient.get(`${environment.apiBaseUrl}${'hr-api/filter/get-client-list'}`)
  }
  getbrand(data:any): Observable<any> {
    return this.httpClient.get(`${environment.apiBaseUrl}${'hr-api/filter/get-brand-list'}`,data)
  }

  getTimesheet(data:any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}${'hr-api/timesheet/get-week-timesheet'}`,data)
  }
  getMasterTimesheet(data:any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}${'hr-api/timesheet/get-week-timesheet-master'}`,data)
  }

  saveTimesheet(data:any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}${'hr-api/timesheet/update-weektimesheet-details'}`,data)
  }

  getTimesheetStatus(data:any): Observable<any> {
    return this.httpClient.post(`${environment.apiBaseUrl}${'hr-api/timesheet/get-timesheet-status'}`,data) 
  }
}
