import { Injectable } from '@angular/core';
import {  ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<boolean> {

  constructor(
    public authService: AuthService,
    public router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isLoggedIn()) {
      this.authService.logoutAndRedirect();
    }
    //checking for designation permission
    const requiresDesignation = route.data['requiresDesignation'] || false;
    if(requiresDesignation) {
      const designation = this.authService.getUserDesignation();

      
      if(!this.authService.isAuthorized(designation)) {
        this.authService.logoutAndRedirect();
      }
    }
    return this.authService.isLoggedIn();
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return confirm('Discard unsaved changes?');
  }

}
