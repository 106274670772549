import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SessionService } from './session.service';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  constructor(
    private apiService: ApiService,private sessionService: SessionService,
  ) {}

  login(data: any) {
    return this.apiService.post('api/user-validation', data);
  }

  getuserName(data: any) {
    return this.apiService.post('api/validate-pwd-key', data);
  }

  forgotPassowrd(data: any) {
    const obj = {
      user_id: data
    }
    return this.apiService.post('api/send-pwd-confirmation', obj);
  }
  public isLoggedIn() {
    const user = this.sessionService.getUserAttributes();
    const userName = this.sessionService.getUsername();
    // check for token expiry, will fail for no token or invalid token
    const token = sessionStorage.getItem('token');
   if (user != null && userName) {
      return true;
    } else {
      return false;
    }
  }


  logoutAndRedirect() {
    Auth.signOut();
    sessionStorage.clear();
    localStorage.clear()
    window.location.href = "/login";
  }

  getUserDesignation() {
    let employeeData:any = sessionStorage.getItem('EmployeeData') || '';
    employeeData =JSON.parse(employeeData)[0];
    return employeeData.designation;
  }

  isAuthorized(designation: string): boolean {
    // Define allowed designations, modify as per your requirements
    const allowedDesignations = ['Project Manager', 'Admin']; 
    // Check if the user's designation is one of the allowed designations
    return allowedDesignations.includes(designation);
  }
  
}
