import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import moment from 'moment';
import { ManagerService } from 'src/app/services/manager-service.service';
import { MatInputCellRendererComponent } from '../mat-input-cell-renderer';
import { MatSelectCellRendererComponent } from '../mat-select-cell-renderer';
import { AutocompleteCellRenderer } from '../mat-autocomplete-cell-render';
import { Subject, takeUntil } from 'rxjs';
import { CellRendererComponent } from 'ag-grid-community/dist/types/core/components/framework/componentTypes';
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'app-cloneweek',
  templateUrl: './cloneweek.component.html',
  styleUrls: ['./cloneweek.component.scss']
})
export class CloneweekComponent {
  @Input() passToClone:any;
  @Input() dataSet:any;
  @Output() selectedData = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<void>();
  isLoading:boolean = false;
  dateRangeForm!: FormGroup;
  startDate:any;
  endDate:any;
  columnDefs!: ColDef[];
  private gridApi!: GridApi;
  
  rowData: any = [];
  private destroy$ = new Subject<void>();
  constructor(private fb: FormBuilder, private managerService: ManagerService,private helpService: HelpService) {
    this.dateRangeForm = this.fb.group({
      startDate: [null, Validators.required]
    });
  }

  public gridOptions: GridOptions = {
    rowSelection: 'multiple',
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      editable: false,
      resizable: true,
    }
    
    // rowHeight: 40,
    // headerHeight: 40,
  };
  
  onSubmitdate(event: any,picker:any) {

    if (this.dateRangeForm.valid) {
        const selectedDate = new Date(this.dateRangeForm.value.startDate);
        this.startDate = selectedDate.toLocaleDateString('en-CA');
        
        const endDate = new Date(selectedDate);
        endDate.setDate(selectedDate.getDate() + 6);
        this.endDate = endDate.toLocaleDateString('en-CA'); 

        let payload = {
          "employee_key": this.passToClone.employee_key,
          "week_start_dt":this.startDate,
          "week_end_dt":this.endDate
        }
        this.isLoading = true;
        this.managerService.getToCloneWeekData(payload).subscribe((res: any) => {
          if (res) {
            this.rowData = res;
            this.isLoading = false;
          }
          this.getColumnDef(this.dataSet)
        })

    }
  }

  getMonday(d: Date): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(d.setDate(diff));
  }

  myCloneFilter: (d: Date | null) => boolean = (d: Date | null): boolean => {
    if (!d) {
      return false;
    }
    
   const day =  moment(d).day();
   //const day = d.getDay();
    const today = new Date();
    const currentMonday = this.getMonday(today);
    const minDate = new Date(currentMonday.getFullYear(), currentMonday.getMonth(), currentMonday.getDate() - 14);
    const maxDate = new Date(currentMonday.getFullYear(), currentMonday.getMonth(), currentMonday.getDate() + 0);
    // Return true for weekdays (Monday to Friday) and false for weekends (Saturday and Sunday)
    return day !== 0 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6&& d <= maxDate;
  };


  submitSelection() {
    //const selected = this.data.filter(item => item.selected);
    this.selectedData.emit('Done');
    this.close();
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  getColumnDef(event: any) {
    
    //console.log("startDate: ", selectedweeks.week_start_dt, "endDate: ", endDate);
    const startDate = new Date( this.startDate);
    const endDate = new Date( this.endDate);
    // Initialize an array to hold the dynamically created objects
    const columns = [];
    // Loop through each date from startDate to endDate
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
      const day = date.getDate();
      const month = date.toLocaleString('en-US', { month: 'short' });
      const dayName = date.toLocaleDateString('en-US', { weekday: 'short' }); //get day name
      //console.log(month);
      // Create the object for the current date
      const column = {
        headerName: ` ${day} ${month} ${dayName}`,
         headerClass: 'date-custom-header',
        wrapHeaderText: true,
        editable: false,
        field: moment(date).format('YYYY-MM-DD'),
        minWidth: 100,
        maxWidth: 100,
        aggFunc: "sum",
        cellRendererParams: (params:any) => ({
          fromClone: true,
        }),
        // editable:true
        cellRenderer: MatInputCellRendererComponent,
      };

      // Add the object to the columns array
      columns.push(column);
    }

    this.columnDefs = [
      { headerName: '',
        menuTabs: [],
        minWidth: 43,
        maxWidth: 43,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      {
        headerName: 'Client Name',
        minWidth: 140,
        maxWidth: 140,
        field: 'client_key',
       // headerClass: 'date-custom-header',
        //headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          disabled: true,  // Disable this instance
          rawData: event.client_key,
          filters: {},
          props: {
            value: "client_key",
            label: "client_nm"
          }
        }),
      },
      {
        headerName: 'Brand Name',
        field: 'brand_key',
        minWidth: 138,
        maxWidth: 138,
       // headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          disabled: true,
          rawData: event.brand_key,
          filters: {
            'client_key': params?.data?.client_key,
          },
          props: {
            value: "brand_key",
            label: "brand_nm"
          }
        })
      },
      {
        headerName: ' Project Name',
        field: 'project_key',
        minWidth: 146,
        maxWidth: 146,
       // headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          disabled: true,
          rawData: event.project_key,
          filters: {
            'client_key': params?.data?.client_key,
            'brand_key': params?.data?.brand_key,
          },
          props: {
            value: "project_key",
            label: "project_nm"
          }
        }),
      },
      {
        headerName: 'Task Name',
        field: 'task_key',
        minWidth: 130,
        maxWidth: 130,
       // headerComponent: CustomHeaderComponent,
        cellRenderer: MatSelectCellRendererComponent,
        cellRendererParams: (params: any) => ({
          disabled: true,
          rawData: event.task_key,
          filters: {
            'client_key': params?.data?.client_key,
            'brand_key': params?.data?.brand_key,
            'project_key': params?.data?.project_key,
          },
          props: {
            value: "task_key",
            label: "task_description"
          }
        }),
      },
      {
        headerName: 'Jira #',
        field: 'jira_no',
        minWidth: 110,
        maxWidth: 110,
       // headerComponent: CustomHeaderComponent,
        cellRenderer: AutocompleteCellRenderer,
        cellRendererParams: (params: any) => ({
          disabled: true,
          rawData: event.jira_no,
          filters: {
            'project_key': params?.data?.project_key,
          },
          props: {
            value: "jira_no",
            label: "jira_task_no",
            desc:'jira_desc'
          }
        }),
      },
      ...columns,
    ];

    // console.log(this.columnDefs)
    // if(this.rowData.length>0){
   // this.addGrandTotalRow(this.gridApi);
  // }

    this.isLoading = false
  }
  isButtonDisabled:boolean=true;
  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    this.isButtonDisabled = selectedRows.length === 0; // Disable button if no rows are selected
  }

  selectedRowData:any;
  cloneTimesheet() {
    this.isLoading = !this.isLoading;
    let selectedNodes = this.gridApi.getSelectedNodes();
    this.selectedRowData = selectedNodes.map((node: any) => node.data);
    //console.log("udk", this.selectedRowData);
    let timesheetsData = this.helpService.transformData(this.selectedRowData)
    let payload = {
      ...timesheetsData,
      employee_key:this.passToClone.employee_key,
      status_key: 1,
      timesheet_key: null,
      timesheet_nm: this.passToClone.timesheet_nm,
      week_end_dt: this.passToClone.week_end_dt,
      week_start_dt: this.passToClone.week_start_dt,
    }

    this.managerService.postCloneWeekData(payload)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
      if(res) { 
        this.isLoading = !this.isLoading;
        this.submitSelection();
      }
      
    })

    console.log("payload", payload);
    // this.isLoading = !this.isLoading;
    //  this.submitSelection();
   }

  close() {
    this.closeModal.emit();
  }
}
