import { Component } from '@angular/core';
import { AutoLogoutService } from './services/auto-logout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'du-hrms';

  constructor(private autoLogoutService: AutoLogoutService
  ) { }
}
