import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserTimesheetComponent } from './user-timesheet/user-timesheet.component';
import { ManagerviewComponent } from '../home/manager-vies/managerview/managerview.component';
import { AuthGuard } from 'src/app/services/auth-guard';
import { TrainingsComponent } from './trainings/trainings.component';
import { ApprovaltimesheetComponent } from './approvaltimesheet/approvaltimesheet.component';

const routes: Routes = [
  {
    path: 'user-timesheet',
    component: UserTimesheetComponent
  },
  {
    path:'manager-view',
    canActivate:[AuthGuard],
    component:ManagerviewComponent,
    //data: { requiresDesignation: true }  //once DB is ready as per designation security it will implemented
  },
  {
    path: 'approval-view',
    component:ApprovaltimesheetComponent,
    canActivate:[AuthGuard],
   // data: { requiresDesignation: true }
  },
  {
    path: 'trainings',
    component:TrainingsComponent,
    canActivate:[AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
