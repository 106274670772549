// custom-header.component.ts
import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

@Component({
  selector: 'app-custom-header',
  template: `
    <div class="custom-header">
      <span>{{params.displayName}}</span><span style='color:red; margin-left: 5px; font-weight: bolder;'>*</span>
    </div>
  `,
  styles: [`
    .custom-header {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `]
})
export class CustomHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;
constructor(){}
  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams): boolean {
    return true;
  }
}
